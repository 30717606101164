import React, { useEffect, useState } from "react";
import MainBanner from "./Component/MainBanner";
import TryBox from "./Component/TryBox";
import ProcessDiagram from "./Component/ProcessDiagram";
import AdvantageBox from "./Component/AdvantageBox";
import styled from "styled-components";
import { commonStyle } from "../../Common/commonStyle";
import { colors } from "../../Utils/colors";

import MobilePage from "../../Mobile/MobilePage";

const S = {
  MainContainer: styled.div`
    ${commonStyle.commonPageContainer}
  `,
  BackgroundContainer: styled.div<{ color: string }>`
    background-color: ${(props) => props.color};
  `,
};
const MainPage = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: 767px)`);

    const handleChange = (e: MediaQueryListEvent) => {
      setIsMobile(e.matches);
    };

    mediaQuery.addEventListener("change", handleChange);

    // 초기값 설정
    setIsMobile(mediaQuery.matches);

    return () => mediaQuery.removeEventListener("change", handleChange);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return isMobile ? (
    <MobilePage />
  ) : (
    <S.MainContainer>
      <S.BackgroundContainer color={colors.white}>
        <MainBanner />
      </S.BackgroundContainer>
      <S.BackgroundContainer color={colors.bg2}>
        <TryBox />
      </S.BackgroundContainer>
      <S.BackgroundContainer color={colors.white}>
        <ProcessDiagram />
      </S.BackgroundContainer>
      <S.BackgroundContainer color={colors.bg2}>
        <AdvantageBox />
      </S.BackgroundContainer>
    </S.MainContainer>
  );
};

export default MainPage;
