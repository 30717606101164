import React, { useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../../../Utils/colors";
import CheapfakeImg from "../../../../Common/CheapfakeImg/CheapfakeImg";
import { useDetectionState } from "../../../../GlobalState/useDetectionState";
interface ReuploadProps {
  reason: string | null;
  setIsAgainUpload: (value: boolean) => void;
  setMileageStage: (value: number) => void;
  setIsMileageUploaded: (value: boolean) => void;
}
const S = {
  ReuploadContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
  `,
  UpperBox: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    & > span {
      color: ${colors[900]};
      text-align: center;

      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 30px */
      letter-spacing: -0.3px;
    }
  `,
  ContentBox: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 36px;
    .TextBox {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      & > span {
        text-align: center;

        font-size: 2rem;
        font-style: normal;
        line-height: 100%; /* 20px */
        letter-spacing: -0.2px;
      }
      & > span:nth-child(1) {
        color: ${colors.primary};
        font-weight: 600;
      }
      & > span:nth-child(2) {
        color: ${colors[700]};
        font-weight: 500;
      }
    }
  `,
  ColumnTextBox: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  `,
  Button: styled.button`
    display: flex;
    padding: 16px 52px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 999px;
    background: ${colors[700]};
    border: none;
    cursor: pointer;
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      & > span {
        color: ${colors[100]};
        text-align: center;

        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 18px */
        letter-spacing: -0.18px;
      }
    }
  `,
};
const ReUploadBox = ({
  reason,
  setMileageStage,
  setIsAgainUpload,
  setIsMileageUploaded,
}: ReuploadProps) => {
  const handleBtnClick = () => {
    setIsAgainUpload(false);
    setMileageStage(1);
  };
  useEffect(() => {
    setIsMileageUploaded(false);
  }, []);
  return (
    <S.ReuploadContainer>
      <S.ContentBox>
        <S.UpperBox>
          <CheapfakeImg name="alert-triangle" type="png" />
          <span>다른 이미지를 첨부해 주세요</span>
        </S.UpperBox>
        {reason === "filesize" && (
          <div className="TextBox">
            <span>파일 용량 제한</span>
            <span>10MB 이하</span>
          </div>
        )}
        {reason !== "filesize" && (
          <S.ColumnTextBox>
            <div className="TextBox">
              <span>계기판 이미지</span>
              <span>주행거리가 잘 보이는 정면 촬영 이미지</span>
            </div>
          </S.ColumnTextBox>
        )}
      </S.ContentBox>
      <S.Button onClick={handleBtnClick}>
        <div>
          <CheapfakeImg name="whiterightarrow" type="png" />
          <span>재첨부하기</span>
        </div>
      </S.Button>
    </S.ReuploadContainer>
  );
};

export default ReUploadBox;
