import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import CheapfakeImg from "../../../../Common/CheapfakeImg/CheapfakeImg";
import { S } from "../../Style/uploadboxstyle";

interface UploadProps {
  handleMileageisUploaded: (file: File) => void;
}

const UploadBox = ({ handleMileageisUploaded }: UploadProps) => {
  return (
    <>
      <S.UploadHead>
        <span>이미지 첨부</span>
        <span>*파일 용량 제한 10MB 이하</span>
      </S.UploadHead>
      <S.ItemContainer>
        <S.Item>
          <div>
            <CheapfakeImg name="dashboard" type="svg" />
            <span>
              주행거리 보이는 <span className="Bold">계기판 이미지</span>
            </span>
          </div>
          <S.UploadBtn>
            <CheapfakeImg name="upload" type="svg" />
            <p>첨부하기</p>
            <input
              type="file"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  handleMileageisUploaded(file);
                }
              }}
            />
          </S.UploadBtn>
        </S.Item>
      </S.ItemContainer>

      <S.TextBox>
        <CheapfakeImg name="alert-circle-purple" type="svg" />
        <span>
          첨부하기 버튼 클릭 시{" "}
          <span
            className="Focus"
            onClick={() =>
              window.open(
                "https://safe-ai.notion.site/106f5deaf68a80f2a541e24edfeb11a9?pvs=4",
              )
            }
          >
            개인정보처리방침
          </span>
          에 동의하게 됩니다
        </span>
      </S.TextBox>
    </>
  );
};

export default UploadBox;
