import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      titleBold: "AI-generated image?",
      titleNormal: "Is this an ",
      tab_person: "Portrait Image (Example)",
      tab_artwork: "Artwork Image (Example)",
      tab_nature: "Nature Image (Example)",
      result_header: "Image verification result",
      result_text: "Probability of an image created by AI",
      check_text: "Check out my images",
      file: "File",
      link: "Link",
      upload: "Upload Image",
      check: "check",
      linkUpload: "Paste image address",
      company_name: "SafeAI",
      representative: "Representative: Wonseop Lee",
      address_seoul: "29-7, Seoripul-gil, Seocho-gu, Seoul, Korea",
      address_daejeon:
        "Daejeon Office: #A418, 17, Techno 4-ro, Yuseong-gu, Daejeon, Korea",
      address_europe:
        "EU Office: 11-13 Bd Grande-Duchesse Charlotte, L-1331 Luxembourg",
      personal_info_manager: "Personal Information Manager: Wonsuk Kim",
      privacy_policy: "Privacy Policy",
      terms_of_service: "Terms of Service",
      copyright: "© 2024 SafeAI Inc. All Rights Reserved",
      contact: "Contact us",
      name: "Name",
      namePlaceHolder: "Please enter your name",
      company: "Company name",
      companyPlaceHolder: "Please enter your company name",
      email: "Business email",
      emailPlaceHolder: "Please enter your business email",
      phone: "Contact",
      contactPlaceHolder: "Please enter your contact information",
      details: "Inquiry details",
      detailsPlaceHolder: "Please enter your inquiry details",
      privacyPolicyAgree:
        "I agree to the Privacy Policy and Terms of Service(required) *",
      send: "Send",
    },
  },
  ko: {
    translation: {
      titleBold: "AI로 만든 이미지",
      titleNormal: "일까요?",
      tab_person: "인물 이미지(예시)",
      tab_artwork: "작품 이미지(예시)",
      tab_nature: "자연 이미지(예시)",
      result_header: "이미지 확인 결과",
      result_text: "AI 생성 이미지일 확률",
      check_text: "직접 확인해 보세요!",
      file: "파일",
      link: "링크",
      upload: "이미지 업로드",
      check: "등록",
      linkUpload: "이미지 주소(URL) 붙여넣기",
      company_name: "(주)세이프에이아이",
      representative: "대표: 이원섭",
      address_seoul: "서울시 서초구 서리풀길 29-7",
      address_daejeon: "대전 지사: 대전시 유성구 테크노4로 17 A418",
      address_europe:
        "유럽 지사: 11-13 Bd Grande-Duchesse Charlotte, L-1331 Luxembourg",
      personal_info_manager: "개인정보관리책임자: 김원석",
      privacy_policy: "개인정보처리방침",
      terms_of_service: "서비스 이용약관",
      copyright: "Copyright © 2024 SafeAI. All Rights Reserved",
      contact: "문의하기",
      name: "성함",
      namePlaceHolder: "성함을 입력해 주세요",
      company: "회사명",
      companyPlaceHolder: "회사명을 입력해 주세요",
      email: "비즈니스 이메일",
      emailPlaceHolder: "비즈니스 이메일을 입력해 주세요",
      phone: "연락처",
      contactPlaceHolder: "연락처를 입력해 주세요",
      details: "문의 내용",
      detailsPlaceHolder: "문의 내용을 입력해 주세요",
      privacyPolicyAgree:
        "개인정보 보호정책 및 서비스 이용 약관에 동의합니다(필수) *",
      send: "보내기",
    },
  },
};
i18n.use(initReactI18next).init({
  resources,
  lng: "ko",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // React의 기본 XSS 방지 사용
  },
});
export default i18n;
