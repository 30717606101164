import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Common/Header/Header";
import Footer from "./Common/Footer/Footer";
import FloatingBtn from "./Common/FloatingBtn";
import ContactModal from "./Common/Modal/ContactModal";
import useContact from "./GlobalState/useContact";
import useMenu from "./GlobalState/useMenu";
import DrawerMenu from "./Common/Modal/DrawerMenu";
import AIDetectHeader from "./AIdetect/AIDetectHeader";
import AIDetectFooter from "./AIdetect/AIDetectFooter";
import useIsMobile from "./Hooks/useIsMobile";

const Main = () => {
  const { isContact, setIsContact } = useContact();
  const { isMenu, setIsMenu } = useMenu();

  const [isAICheck, setIsAICheck] = useState<boolean>(false);

  const location = useLocation();
  const isMobile = useIsMobile();
  useEffect(() => {
    if (location.pathname.replace("/", "") === "aicheck") {
      setIsAICheck(true);
    } else setIsAICheck(false);
    console.log(location.pathname.replace("/", ""));
  }, [location]);
  return (
    <>
      {isContact && <ContactModal />}
      {isMenu && <DrawerMenu />}
      {!isMobile && !isAICheck && (
        <Header setIsContact={setIsContact} setIsMenu={setIsMenu} />
      )}
      {isAICheck && <AIDetectHeader />}
      <Outlet />
      {!isMobile && !isAICheck && <FloatingBtn />}

      {!isMobile && !isAICheck && <Footer />}
      {isAICheck && <AIDetectFooter />}
    </>
  );
};

export default Main;
