import React from "react";
import styled from "styled-components";
import { colors } from "../../Utils/colors";
import { useNavigate } from "react-router-dom";

interface SubMenuProps {
  setIsSubMenuOn: (value: boolean) => void;
}

const S = {
  Container: styled.div`
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid ${colors[200]};
    background: ${colors.white};
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    top: 56px;
    @media ${(props) => props.theme.device.tablet} {
      display: none;
    }
  `,
  Content: styled.div`
    display: flex;
    padding: 8px 12px;
    align-items: center;
    white-space: nowrap;
    border-radius: 8px;
    background: ${colors.white};
    &:hover {
      background: ${colors[100]};
    }
    & > span {
      color: ${colors[700]};
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      letter-spacing: -0.2px;
    }
  `,
};
const SubMenu = ({ setIsSubMenuOn }: SubMenuProps) => {
  const navigate = useNavigate();
  return (
    <S.Container onMouseLeave={() => setIsSubMenuOn(false)}>
      <S.Content onClick={() => navigate("/detection")}>
        <span>보험청구서 위조 검토</span>
      </S.Content>
      <S.Content onClick={() => navigate("/mileage")}>
        <span>자동차 주행거리 위조 검토</span>
      </S.Content>
      <S.Content onClick={() => navigate("/aicheck")}>
        <span>AI 생성 이미지 검토</span>
      </S.Content>
    </S.Container>
  );
};

export default SubMenu;
