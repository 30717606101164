import styled from "styled-components";
import { colors } from "../../../Utils/colors";

export const S = {
  MainContainer: styled.div`
    display: flex;
    padding: 52px 48px;
    flex-direction: column;
    max-width: 1600px;
    align-self: stretch;
    margin: 68px auto 0 auto;
    box-sizing: border-box;
    @media ${(props) => props.theme.device.mobile} {
      display: flex;
      flex-direction: column;
      padding: 36px 20px;
      justify-content: center;
      align-items: flex-start;
      align-self: stretch;
      margin: 0 auto 0 auto;
    }
  `,
  Head: styled.div`
    display: flex;
    align-items: center;
    gap: 64px;
    align-self: stretch;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 36px;
    @media ${(props) => props.theme.device.mobile} {
      margin-bottom: 6px;
    }
  `,

  ContentBox: styled.div`
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    @media ${(props) => props.theme.device.tablet} {
      display: flex;
      flex-direction: column;
      & > div:nth-child(1) {
        display: none;
      }
    }
  `,
  Title: styled.span`
    color: ${colors[900]};
    font-size: 4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 52px */
    letter-spacing: -0.8px;
    @media ${(props) => props.theme.device.mobile} {
      font-size: 2.6rem;
    }
  `,
  StageBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  `,
  StageItem: styled.div<{ isNow: boolean }>`
    display: flex;
    padding: 16px;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    cursor: pointer;
    background: ${(props) => (props.isNow ? colors[200] : "none")};
    & > div {
      display: flex;
      align-items: center;
      gap: 12px;
      flex: 1 0 0;
      .NumberBox {
        display: flex;
        width: 22px;
        height: 22px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: ${(props) => (props.isNow ? colors[900] : colors[600])};
        & > p {
          color: ${(props) => (props.isNow ? colors.white : colors[100])};
          text-align: center;

          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%; /* 16px */
          letter-spacing: -0.16px;
        }
      }
      .Text {
        color: ${colors[800]};

        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 22px */
        letter-spacing: -0.2px;
      }
    }
  `,
  Stage: styled.div<{ currentNum: number }>`
    display: flex;
    align-items: center;
    gap: 12px;
    text-align: center;

    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 18px */
    letter-spacing: -0.18px;

    & > div {
      display: flex;
      width: 32px;
      height: 32px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 2px solid ${colors[700]};
      color: ${colors[700]};

      ${({ currentNum }) => `
        &:nth-child(${currentNum * 2 - 1}) {
          color: ${colors.white};
          border: 2px solid ${colors.primary};
          background: ${colors.primary};
        }
      `}
    }
    & > img {
      width: 32px;
    }
  `,
  UploadWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 48px;

    flex: 1 0 0;
    width: 100%;
  `,

  ContentWrapper: styled.div`
    padding: 52px;
    display: flex;
    flex-direction: column;
    gap: 28px;
    align-self: stretch;
    border-radius: 16px;
    background: ${colors.white};
  `,
  ResultWrapper: styled.div`
    width: 100%;
  `,
  Bottom: styled.div`
    margin-top: 24px;
    display: flex;
    height: 64px;
    align-items: center;
    gap: 6px;
    justify-content: flex-end;
  `,
  BtnSet: styled.div`
    display: flex;
    align-items: flex-start;
    gap: 12px;
  `,
  ReloadBtn: styled.button`
    display: flex;
    height: 56px;
    width: 200px;
    padding: 20px 36px;
    justify-content: center;
    align-items: center;
    border-radius: 99px;
    border: 1px solid ${colors[700]};
    background: ${colors[100]};
    cursor: pointer;
    color: ${colors[700]};
    text-align: center;

    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 18px */
    letter-spacing: -0.18px;
  `,
  DetectBtn: styled.button<{ isFilled: boolean }>`
    display: flex;
    height: 56px;
    width: 296px;
    padding: 20px 40px;
    border: none;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 99px;
    background: ${colors[400]};
    background-color: ${(props) =>
      props.isFilled ? colors.primary : colors[400]};
    color: ${(props) => (props.isFilled ? colors[100] : colors[600])};
    text-align: center;

    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 18px */
    letter-spacing: -0.18px;
    cursor: pointer;
  `,
};
