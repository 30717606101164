import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { S } from "./Styles/uploadboxstyle";
import CheapfakeImg from "../../../../Common/CheapfakeImg/CheapfakeImg";

import loadingAnimationWhite from "../../../../../src/Common/loading_white.json";
import loadingAnimationBlue from "../../../../../src/Common/loading_blue.json";
import Lottie from "lottie-react";
interface UploadProps {
  handleDiagnosisUpload: (file: File) => void; // 파일 객체를 직접 받도록 수정
  handleCarisUpload: (file: File) => void; // 파일 객체를 직접 받도록 수정
  setSampleNum: (value: number | null) => void;
  isCarLoading: boolean;
  isDiagnosisLoading: boolean;
}

const UploadBox = ({
  handleDiagnosisUpload,
  handleCarisUpload,
  setSampleNum,
  isCarLoading,
  isDiagnosisLoading,
}: UploadProps) => {
  return (
    <>
      <S.UploadHead>
        <span>이미지 첨부</span>
        <span>*파일 용량 제한 10MB 이하</span>
      </S.UploadHead>
      <S.ItemContainer>
        <S.Item>
          <div>
            <CheapfakeImg name="document" type="svg" />
            <p>병원진단서 이미지</p>
          </div>
          <S.UploadBtn>
            {isDiagnosisLoading ? (
              <Lottie
                style={{ height: "30px", width: "80px" }}
                animationData={loadingAnimationBlue}
              />
            ) : (
              <>
                <CheapfakeImg name="upload" type="svg" />
                <p>첨부하기</p>
                <input
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      setSampleNum(null);
                      handleDiagnosisUpload(file);
                    }
                  }}
                />
              </>
            )}
          </S.UploadBtn>
        </S.Item>
        <p>or</p>
        <S.Item>
          <div>
            <CheapfakeImg name="caracc" type="svg" />
            <p>차량사고 이미지</p>
          </div>
          <S.UploadBtn>
            {isCarLoading ? (
              <Lottie
                style={{ height: "30px", width: "80px" }}
                animationData={loadingAnimationBlue}
              />
            ) : (
              <>
                <CheapfakeImg name="upload" type="svg" />
                <p>첨부하기</p>
                <input
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      setSampleNum(null);
                      handleCarisUpload(file);
                    }
                  }}
                />
              </>
            )}
          </S.UploadBtn>
        </S.Item>
      </S.ItemContainer>
      <S.TextBox>
        <CheapfakeImg name="alert-circle-purple" type="svg" />
        <span>
          첨부하기 버튼 클릭 시{" "}
          <span
            className="Focus"
            onClick={() =>
              window.open(
                "https://safe-ai.notion.site/106f5deaf68a80f2a541e24edfeb11a9?pvs=4",
              )
            }
          >
            개인정보처리방침
          </span>
          에 동의하게 됩니다
        </span>
      </S.TextBox>
    </>
  );
};

export default UploadBox;
