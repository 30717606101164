import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

import CheapfakeImg from "../../../../Common/CheapfakeImg/CheapfakeImg";
import { useDetectionState } from "../../../../GlobalState/useDetectionState";
import { Layer, Line, Stage } from "react-konva";
import { CarSegRes } from "../../../../Utils/type";
import { S } from "../../Style/resultboxstyle";
import useIsMobile from "../../../../Hooks/useIsMobile";

interface ResultProps {
  setIsDetected: (value: boolean) => void;
  finalResult: number | undefined;
  setIsMileageUploaded: (value: boolean) => void;
  setMileageStage: (value: number) => void;
  file: File | null;
  userInput: number;
  mileageOutput: number;
}

const ResultBox = ({
  setIsDetected,
  finalResult,
  setIsMileageUploaded,
  setMileageStage,
  file,
  userInput,
  mileageOutput,
}: ResultProps) => {
  const fileUrl = useMemo(
    () => (file ? URL.createObjectURL(file) : undefined),
    [file],
  );
  const handleReloadClick = () => {
    setIsMileageUploaded(false);

    setIsDetected(false);
    setMileageStage(1);
  };
  const isMobile = useIsMobile();
  return (
    <S.Container>
      <S.ImgBox>
        <img src={fileUrl} alt="image" />
      </S.ImgBox>
      <S.ContentBox>
        {isMobile ? (
          <>
            <CheapfakeImg name="shield-check" type="png" />
            <S.ResultTxt>
              <div>
                <span>
                  이미지 내 주행거리
                  <span className="Bold">{mileageOutput}</span>km
                </span>
              </div>
            </S.ResultTxt>
          </>
        ) : (
          <>
            {finalResult === 0 && (
              <>
                <CheapfakeImg name="shield-check" type="png" />
                <p>일치해요</p>
                <S.ResultTxt>
                  <div>
                    <span>
                      이미지 내 주행거리
                      <span className="Bold">{mileageOutput}</span>km
                    </span>
                  </div>
                  <div>
                    <span>
                      입력한 주행거리<span className="Bold">{userInput}</span>km
                    </span>
                  </div>
                </S.ResultTxt>
              </>
            )}
            {finalResult === 1 && (
              <>
                <CheapfakeImg name="shield-red" type="png" />
                <p>일치하지 않아요</p>
                <S.ResultTxt>
                  <div>
                    <span>
                      이미지 내 주행거리
                      <span className="Bold">{mileageOutput}</span>km
                    </span>
                  </div>
                  <div>
                    <span>
                      입력한 주행거리<span className="Bold">{userInput}</span>km
                    </span>
                  </div>
                </S.ResultTxt>
              </>
            )}
          </>
        )}

        <S.RetryBtn onClick={handleReloadClick}>다시하기</S.RetryBtn>
      </S.ContentBox>
    </S.Container>
  );
};

export default ResultBox;
