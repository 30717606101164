import styled from "styled-components";
import { colors } from "../../Utils/colors";

export const S = {
  HeaderContainer: styled.div`
    display: flex;
    align-items: center;
    padding: 8px 48px;
    border-bottom: 1px solid ${colors[200]};
    background-color: ${colors.white};
    height: 68px;
    box-sizing: border-box;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5;
  `,
  Logo: styled.div`
    padding: 8px 12px 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    & > p {
      color: ${colors[900]};

      font-size: 24px;
      font-style: normal;
      font-weight: 700;
    }
  `,
  CenterBox: styled.div`
    //테블릿일 때 보이지 않도록 하기
    @media ${(props) => props.theme.device.tablet} {
      display: none;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 72px;
    flex: 1 0 0;
  `,
  Item: styled.div<{ isSelected: boolean }>`
    display: flex;
    padding: 8px 12px;
    align-items: center;
    border-radius: 8px;
    gap: 8px;
    cursor: pointer;
    position: relative;
    &:hover {
      background: ${colors[100]};
      .Free {
        background-color: ${colors.primary3};
      }
    }
    & > p {
      color: ${(props) => (props.isSelected ? colors.primary : colors[900])};

      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      letter-spacing: -0.18px;
    }
    .Free {
      display: flex;
      padding: 4px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background-color: ${colors.primary2};
      .item {
        color: ${colors.primary};

        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 12px */
        letter-spacing: -0.12px;
      }
    }
  `,
  ContactBtn: styled.button`
    //테블릿일 때 보이지 않도록 하기
    @media ${(props) => props.theme.device.tablet} {
      display: none;
    }
    margin-left: auto;
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid ${colors.primary4};
    background-color: ${colors.white};
    cursor: pointer;
    &:hover {
      background-color: ${colors.primary2};
    }
    & > p {
      color: ${colors.primary};

      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 16px */
      letter-spacing: -0.16px;
    }
  `,
  MenuBar: styled.div`
    display: none;

    @media ${(props) => props.theme.device.tablet} {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      & > * {
        width: 24px;
        height: 24px;

        cursor: pointer;
      }
    }
  `,
};
