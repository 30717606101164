import styled from "styled-components";
import { colors } from "../../../../../Utils/colors";

export const S = {
  Container: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    min-height: 550px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 16px 16px 16px 16px;
    @media ${(props) => props.theme.device.tablet} {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  `,
  ImgBox: styled.div`
    border-radius: 16px 0px 0px 16px;
    background: ${colors[300]};
    width: 100%;
    @media ${(props) => props.theme.device.tablet} {
      border-radius: 16px 16px 0px 0px;
      max-height: 464px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    position: relative;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  `,
  KonvaContainer: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
  `,
  ContentBox: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0px 16px 16px 0px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background: ${colors.white};
    padding: 40px 0;
    @media ${(props) => props.theme.device.tablet} {
      border-radius: 0 0 16px 16px;
    }
    & > img {
      width: 64px;
      height: 64px;
      margin-bottom: 8px;
    }
    & > p {
      color: ${colors[900]};
      text-align: center;

      font-size: 3.2rem;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 32px */
      letter-spacing: -0.32px;
    }
  `,
  ResultTxt: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    margin-top: 28px;
    .Txt {
      display: flex;
      gap: 6px;
      & > img {
        width: 20px;
        height: 20px;
      }
      & > span {
        color: ${colors[700]};

        font-size: 2rem;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 18px */
        letter-spacing: -0.2px;
      }
      & > span:nth-child(3) {
        font-weight: 700;
      }
    }
  `,
  RetryBtn: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 76px;
    margin-top: 60px;
    border-radius: 12px;
    border: none;
    background: ${colors[700]};
    color: ${colors[100]};
    text-align: center;

    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 18px */
    letter-spacing: -0.18px;
    cursor: pointer;
  `,
};
