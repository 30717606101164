import React, { useEffect, useRef, useState } from "react";
import { S } from "./Styles/diagnosisformstyle";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import { Controller, UseFormReturn } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { format } from "date-fns";
import { DiagnosisForm } from "../../../../../Utils/type";
import CheapfakeImg from "../../../../../Common/CheapfakeImg/CheapfakeImg";
import TechExplainModal from "../Modal/TechExplainModal";
import { DefaultClaimType } from "../../../../../Utils/defaultclaim";

interface Props {
  file: string | undefined;
  setIsFilled: (value: boolean) => void;
  onSubmitHandler: (data: DiagnosisForm) => void;
  formMethods: UseFormReturn<DiagnosisForm>;
  sampleClaim: DefaultClaimType | undefined;
  formRef: React.RefObject<HTMLFormElement>;
}

const DiagnosisClaimForm = ({
  file,
  setIsFilled,
  onSubmitHandler,
  formMethods,
  sampleClaim,
  formRef,
}: Props) => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const datePickerRef = useRef<HTMLDivElement | null>(null);
  const { register, watch, setValue, handleSubmit, reset } = formMethods;
  const [isClicked, setIsClicked] = useState<boolean>(false);

  // 날짜 변경 및 sampleClaim 초기화 처리
  const handleChange = (
    dates: [Date | null, Date | null],
    claim?: DefaultClaimType,
  ) => {
    let adjustedStart: Date | undefined;
    let adjustedEnd: Date | undefined;

    if (claim) {
      adjustedStart = claim.startDate
        ? new Date(
            claim.startDate.getTime() -
              claim.startDate.getTimezoneOffset() * 60000,
          )
        : undefined;
      adjustedEnd = claim.endDate
        ? new Date(
            claim.endDate.getTime() - claim.endDate.getTimezoneOffset() * 60000,
          )
        : undefined;
    } else {
      const [start, end] = dates;
      adjustedStart = start
        ? new Date(start.getTime() - start.getTimezoneOffset() * 60000)
        : undefined;
      adjustedEnd = end
        ? new Date(end.getTime() - end.getTimezoneOffset() * 60000)
        : undefined;
    }

    setStartDate(adjustedStart);
    setEndDate(adjustedEnd);
    console.log(adjustedStart);
    console.log(adjustedEnd);
    if (adjustedStart) setValue("start", adjustedStart);
    if (adjustedEnd) setValue("end", adjustedEnd);
    if (adjustedStart && adjustedEnd) setIsOpen(false);
  };

  // 달력 외부 클릭 시 닫기
  const handleClickOutside = (event: MouseEvent) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // 모든 입력 필드가 채워졌는지 확인
  useEffect(() => {
    const subscription = watch((value) => {
      const { start, end, price, code, etc } = value;
      const allFieldsFilled = start && end && price && code;
      setIsFilled(Boolean(allFieldsFilled));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  // 파일 URL을 폼 상태에 설정
  useEffect(() => {
    setValue("file", file);
  }, [file]);

  // sampleClaim 초기화
  useEffect(() => {
    if (sampleClaim) {
      handleChange([null, null], sampleClaim);
      Object.entries(sampleClaim).forEach(([key, value]) => {
        if (key !== "startDate" && key !== "endDate") {
          setValue(key as keyof DiagnosisForm, value as number | string);
        }
      });
    }
  }, [sampleClaim, setValue]);

  useEffect(() => {
    // 컴포넌트 언마운트 시 폼 내용 초기화
    return () => {
      console.log("unmount");
      reset({
        file: undefined,
        start: undefined,
        end: undefined,
        price: undefined,
        code: undefined,
        etc: undefined,
      });
    };
  }, []);

  return (
    <S.ClaimBox>
      <S.Title>
        <span>클레임 내용 입력</span>
        <div
          onClick={() => setIsClicked((prev) => !prev)}
          style={{ cursor: "pointer" }}
        >
          <CheapfakeImg name="alert-circle" type="svg" />
        </div>
        {isClicked && (
          <S.ModalPosition>
            <TechExplainModal setIsClicked={setIsClicked} />
          </S.ModalPosition>
        )}
      </S.Title>

      <S.DiagnosisForm onSubmit={handleSubmit(onSubmitHandler)} ref={formRef}>
        <input type="hidden" {...register("file", { required: true })} />
        <div className="Upper">
          <S.Item>
            <label className="FormLabel" htmlFor="code">
              질병 분류 코드
              <span>*</span>
            </label>
            <input
              {...register("code", { required: true })}
              id="code"
              placeholder="(예시) A09"
              className="FormInput"
            />
          </S.Item>

          <S.Item>
            <label className="FormLabel" htmlFor="price">
              총 진료비
              <span>*</span>
            </label>
            <Controller
              name="price"
              control={formMethods.control}
              defaultValue={sampleClaim?.price || 0}
              rules={{ required: true }}
              render={({ field }) => (
                <NumericFormat
                  {...field}
                  id="price"
                  placeholder="(예시) 650,000원"
                  className="FormInput"
                  thousandSeparator={true}
                  suffix=" 원"
                  value={field.value}
                  onValueChange={({ floatValue }) =>
                    field.onChange(floatValue ?? 0)
                  }
                />
              )}
            />
          </S.Item>
        </div>
        <S.Item>
          <label className="FormLabel" htmlFor="period">
            진료 기간
            <span>*</span>
          </label>
          <DatePicker
            locale={ko}
            open={isOpen}
            onInputClick={() => setIsOpen(!isOpen)}
            selected={startDate}
            onChange={(dates) => handleChange(dates)}
            startDate={startDate}
            endDate={endDate}
            minDate={new Date("2000-01-01")}
            maxDate={new Date()}
            selectsRange
            shouldCloseOnSelect={false}
            dateFormat="yyyy.MM.dd"
            customInput={
              <S.CustomInputWrapper>
                <input
                  value={
                    startDate && endDate
                      ? `${format(startDate, "yyyy.MM.dd")} - ${format(endDate, "yyyy.MM.dd")}`
                      : ""
                  }
                  placeholder={"(예시) " + format(new Date(), "yyyy.MM.dd")}
                />
                <S.Img
                  src={
                    process.env.REACT_APP_PUBLIC_URL + "/icon/calendardate.png"
                  }
                />
              </S.CustomInputWrapper>
            }
            wrapperClassName="date-picker-wrapper"
            popperContainer={({ children }) => (
              <div ref={datePickerRef}>{children}</div>
            )}
          />
          <input type="hidden" {...register("start", { required: true })} />
          <input type="hidden" {...register("end", { required: true })} />
        </S.Item>

        <S.Item>
          <label className="FormLabel" htmlFor="etc">
            기타
          </label>
          <input
            {...register("etc", { required: true })}
            id="etc"
            placeholder="(예시) 차량 사고로 인한 팔목 골절"
            className="FormInput"
          />
        </S.Item>
      </S.DiagnosisForm>
    </S.ClaimBox>
  );
};

export default DiagnosisClaimForm;
