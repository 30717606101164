export const colors = {
  white: "#FFFFFF",
  "100": "#F1F3FA",
  "200": "#DFE3EF",
  "300": "#D1D4E6",
  "400": "#BEC1D6",
  "500": "#A9AECF",
  "600": "#9195AB",
  "700": "#56596F",
  "800": "#424351",
  "900": "#26262C",
  primary: "#4959E8",
  primary2: "#ECEEFF",
  primary3: "#DBDEF7",
  primary4: "#A1ABFF",
  primary5: "#19248A",
  bg: "#FDF2FF",
  bg2: "#F5F7FD",
};
