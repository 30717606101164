import styled from "styled-components";
import { colors } from "../../Utils/colors";

export const S = {
  Overlay: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    //다른 요소들보다 위에 올라와서 어디를 누르든 적용되도록
    z-index: 2;
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    width: 480px;
    padding: 28px;
    border-radius: 20px;
    background: ${colors.white};
    box-sizing: border-box;
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 15;
    border: 1px solid ${colors[300]};
    @media ${(props) => props.theme.device.tablet} {
      width: 100%;
      right: 0;
    }
  `,

  CloseBtn: styled.img`
    position: absolute;
    top: 8px;
    right: 12px;
    display: flex;
    padding: 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  `,
  Txt: styled.p`
    color: ${colors[900]};

    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 40px */
    letter-spacing: -0.44px;
    margin-bottom: 16px;
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    align-self: stretch;
  `,
  Item: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    & > label {
      color: ${colors[900]};

      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 23.4px */
      letter-spacing: -0.15px;
    }
    & > input {
      display: flex;
      padding: 12px;
      border: none;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 6px;
      background: ${colors[100]};
      color: ${colors[700]};

      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 136%;
      margin-bottom: 20px;
      &::placeholder {
        color: ${colors[500]};
      }
    }
  `,
  TextArea: styled.textarea`
    padding: 8px 12px 62px 12px;
    box-sizing: border-box;
    border-radius: 6px;
    border: none;
    color: ${colors[700]};

    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 136%;
    background: ${colors[100]};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    margin-bottom: 24px;
    &::placeholder {
      color: ${colors[500]};
    }
  `,
  RadioItem: styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;

    & > input {
      margin: 0;
      width: 18px;
      height: 18px;
    }
    & > label {
      color: ${colors[900]};

      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: -0.14px;
      text-align: left;
    }
  `,
  Btn: styled.button<{ isfilled: boolean }>`
    display: flex;
    padding: 16px 0px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    border: none;
    background-color: ${({ isfilled }) =>
      isfilled ? "var(--900, #26262C)" : colors[400]};
    cursor: pointer;
    & > p {
      color: ${({ isfilled }) => (isfilled ? "white" : colors[600])};
      text-align: center;

      font-size: 1.6rem;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 18px */
      letter-spacing: -0.16px;
    }
  `,
};
