import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { colors } from "../../Utils/colors";
import { commonStyle } from "../../Common/commonStyle";
import MainBox from "./Component/MainBox";

const S = {
  DetectionContainer: styled.div`
    ${commonStyle.commonPageContainer}
  `,
  BackgroundContainer: styled.div<{ color: string }>`
    background-color: ${(props) => props.color};
  `,
};
const MileagePage = () => {
  return (
    <S.DetectionContainer>
      <S.BackgroundContainer color={colors[100]}>
        <MainBox />
      </S.BackgroundContainer>
    </S.DetectionContainer>
  );
};

export default MileagePage;
