// AppRouter.js
import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Main from "./Main";
import MainPage from "./Features/Main/MainPage";
import DetectionPage from "./Features/Detection/DetectionPage";
import WhyPage from "./Features/Why/WhyPage";
import TechPage from "./Features/Tech/TechPage";
import MobilePage from "./Mobile/MobilePage";
import TagManager from "react-gtm-module";
import MileagePage from "./Features/Mileage/MileagePage";
import AIDetectPage from "./AIdetect/AIDetectPage";

const AppRouter = () => {
  const location = useLocation();
  useEffect(() => {
    // 페이지 이동 시 트래킹
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        pagePath: location.pathname,
        pageTitle: document.title,
      },
    });
  }, [location]);
  return (
    <Routes>
      <Route path="/" element={<Main />}>
        <Route index element={<MainPage />} />
        <Route path="detection" element={<DetectionPage />} />
        <Route path="why" element={<WhyPage />} />
        <Route path="technology" element={<TechPage />} />
        <Route path="mileage" element={<MileagePage />} />
        <Route path="aicheck" element={<AIDetectPage />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
