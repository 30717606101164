import React from "react";
import styled from "styled-components";
import { colors } from "../Utils/colors";
import CheapfakeImg from "../Common/CheapfakeImg/CheapfakeImg";
import { useTranslation } from "react-i18next";
import useIsMobile from "../Hooks/useIsMobile";
import { S } from "./Style/footerstyle";

const Footer = () => {
  const { t, i18n } = useTranslation();
  // breakpoint 1280으로 설정
  const isMobile = useIsMobile(1280);
  const toggleLanguage = () => {
    const newLang = i18n.language === "ko" ? "en" : "ko";
    i18n.changeLanguage(newLang); // 언어 변경
  };
  return (
    <S.BackgroundContainer>
      <S.FooterContainer isEnglish={i18n.language === "en"}>
        <S.LogoBox>
          <CheapfakeImg name="SafeAILogo" type="png" />
        </S.LogoBox>
        <S.InfoBox>
          <S.Info>
            <div className="InfoItem">
              <p>{t("company_name")}</p>
              <p className="Both">{t("representative")}</p>
              <p>{t("address_seoul")}</p>
            </div>
            <div className="InfoItem">
              {!isMobile && (
                <>
                  <p className="Right">{t("address_daejeon")}</p>
                  <p>{t("address_europe")}</p>
                </>
              )}
            </div>
            <div className="InfoItem">
              <p className="Right">contact@safeai.kr</p>
              <p>{t("personal_info_manager")}</p>
            </div>
          </S.Info>
          <S.Footer>
            {isMobile ? (
              <>
                <div>
                  <p
                    className="Link"
                    onClick={() =>
                      window.open(
                        "https://safe-ai.notion.site/106f5deaf68a80f2a541e24edfeb11a9?pvs=4",
                      )
                    }
                  >
                    {t("privacy_policy")}
                  </p>
                  <p
                    className="Link"
                    onClick={() =>
                      window.open(
                        "https://safe-ai.notion.site/106f5deaf68a802f8d22cf40a067810a?pvs=4",
                      )
                    }
                  >
                    {t("terms_of_service")}
                  </p>
                </div>
                <div>
                  <p>{t("copyright")}</p>
                </div>
              </>
            ) : (
              <>
                <p
                  className="Link"
                  onClick={() =>
                    window.open(
                      "https://safe-ai.notion.site/106f5deaf68a80f2a541e24edfeb11a9?pvs=4",
                    )
                  }
                >
                  {t("privacy_policy")}
                </p>
                <p
                  className="Link"
                  onClick={() =>
                    window.open(
                      "https://safe-ai.notion.site/106f5deaf68a802f8d22cf40a067810a?pvs=4",
                    )
                  }
                >
                  {t("terms_of_service")}
                </p>
                <p>{t("copyright")}</p>
              </>
            )}
          </S.Footer>
        </S.InfoBox>
        <S.TranslateBtn onClick={toggleLanguage}>
          <CheapfakeImg name="globe-alt" type="svg" />
          <span>{i18n.language === "ko" ? "English" : "Korean"}</span>
          <CheapfakeImg name="arrow-sm-right" type="svg" />
        </S.TranslateBtn>
      </S.FooterContainer>
    </S.BackgroundContainer>
  );
};

export default Footer;
