import styled from "styled-components";
import { colors } from "../../../Utils/colors";

export const S = {
  Container: styled.div`
    display: flex;
    height: 60px;
    padding: 4px 20px;

    justify-content: center;
    align-items: center;
    align-self: stretch;
    box-sizing: border-box;
    background-color: ${colors.white};
  `,
  Content: styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
  `,
  Text: styled.span`
    color: ${colors[900]};

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 18px */
  `,
  MenuBar: styled.div`
    display: none;

    @media ${(props) => props.theme.device.tablet} {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      & > * {
        width: 24px;
        height: 24px;

        cursor: pointer;
      }
    }
  `,
};
