import { useCallback, useState } from "react";
import CheapfakeImg from "../../Common/CheapfakeImg/CheapfakeImg";
import useMenu from "../../GlobalState/useMenu";
import { S } from "./Style/mobileHeaderStyle";

const MobileHeader = () => {
  const { isMenu, setIsMenu } = useMenu();
  const handleMenuClick = useCallback(() => {
    setIsMenu(true);
  }, []);
  return (
    <>
      <S.Container>
        <S.Content>
          <CheapfakeImg name="safeclaim_icon" type="svg" />
          <S.Text>SafeClaim</S.Text>
        </S.Content>
        <S.MenuBar onClick={handleMenuClick}>
          <CheapfakeImg name="menu" type="png" />
        </S.MenuBar>
      </S.Container>
    </>
  );
};

export default MobileHeader;
