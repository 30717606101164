import React from "react";
import styled from "styled-components";
import { colors } from "../Utils/colors";
import useContact from "../GlobalState/useContact";
import useIsMobile from "../Hooks/useIsMobile";
import { useTranslation } from "react-i18next";
const S = {
  Container: styled.div<{ isEnglish: boolean }>`
    display: flex;
    height: 68px;
    padding: 8px 48px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid ${colors[200]};
    background: ${colors.white};
    box-sizing: border-box;
    @media ${(props) => props.theme.device.tablet} {
      max-width: 480px;
      margin: 0 auto;
      padding: ${(props) => (props.isEnglish ? "20px" : "8px 48px")};
    }
  `,
  Content: styled.div`
    display: flex;
    padding: 8px 12px 8px 0px;
    align-items: center;
    flex: 1 0 0;
    & > span {
      color: ${colors[900]};
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -0.48px;
    }
  `,
  ContactBtn: styled.div`
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid ${colors[300]};
    background: ${colors.white};
    cursor: pointer;
    &:hover {
      background: ${colors[100]};
    }
    & > span {
      color: ${colors[900]};
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
  `,
};
const AIDetectHeader = () => {
  const { isContact, setIsContact } = useContact();
  const isMobile = useIsMobile(1280);
  const { t, i18n } = useTranslation();
  return (
    <S.Container
      id={isMobile ? "m_click_header_inquiry" : "click_header_inquiry"}
      isEnglish={i18n.language === "en"}
    >
      <S.Content>
        <span>AI Image Checker</span>
      </S.Content>
      <S.ContactBtn onClick={() => setIsContact(true)}>
        <span>{t("contact")}</span>
      </S.ContactBtn>
    </S.Container>
  );
};

export default AIDetectHeader;
