import styled from "styled-components";
import { colors } from "../../../Utils/colors";

export const S = {
  Container: styled.div`
    display: grid;
    gap: 72px;
    grid-template-columns: minmax(auto, 1fr) 1.25fr;

    width: 100%;
    height: 100%;
    box-sizing: border-box;
    @media ${(props) => props.theme.device.tablet} {
      display: flex;
      flex-direction: column;
      gap: 36px;
      align-items: center;
    }
  `,
  ImageContainer: styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    justify-content: center;
    align-items: center;
  `,
  UpperBox: styled.div`
    display: flex;
    height: 44px;
    padding: 12px 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 12px 12px 0px 0px;
    border: 1px solid ${colors[300]};
    background: #f6f6f6;
    @media ${(props) => props.theme.device.tablet} {
      max-width: 360px;
    }
    & > p {
      color: ${colors[700]};
      text-align: center;

      font-size: 1.8rem;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 18px */
      letter-spacing: -0.18px;
    }
  `,

  ImageBox: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media ${(props) => props.theme.device.tablet} {
      max-height: 360px;
      max-width: 360px;
    }
    border: 1px solid ${colors[300]};
    background: ${colors[300]};
    aspect-ratio: 1 / 1;

    .Content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    .File {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  `,

  BottomBox: styled.div`
    display: flex;
    height: 56px;
    padding: 12px 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 0px 0px 12px 12px;
    border: 1px solid ${colors[300]};

    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    position: relative;
    @media ${(props) => props.theme.device.tablet} {
      max-width: 360px;
    }
    .Text {
      color: ${colors[900]};
      font-size: 1.8rem;
      letter-spacing: -0.18px;
      margin-right: 12px;
      margin-left: 6px;
      cursor: pointer;
    }
    .Plus {
      color: ${colors[600]};
      font-size: 1.5rem;
      letter-spacing: -0.15px;
      cursor: pointer;
    }
  `,
  FormContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    width: 100%;
    .BtnSet {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      margin-left: auto;
      margin-top: 32px;
    }
  `,
  ModalPosition: styled.div`
    position: absolute;
    z-index: 2;
    top: 66px;
    @media ${(props) => props.theme.device.tablet} {
      position: absolute;
      top: -100px;

      z-index: 2;
    }
  `,
};
