import React, { useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../../Utils/colors";
import CheapfakeImg from "../../../../Common/CheapfakeImg/CheapfakeImg";
import sampleAnimation from "../../sampleAnimation.json";
import Lottie from "lottie-react";
import { S } from "./Styles/sampleboxstyle";

import loadingAnimationWhite from "../../../../../src/Common/loading_white.json";
import loadingAnimationBlue from "../../../../../src/Common/loading_blue.json";
interface SampleProps {
  handleDiagnosisUpload: (file: File) => void;
  handleCarisUpload: (file: File) => void;
  setSampleNum: (value: number | null) => void;
  isLoading: boolean;
}

const SampleBox = ({
  handleCarisUpload,
  handleDiagnosisUpload,
  setSampleNum,
  isLoading,
}: SampleProps) => {
  const samples = [
    ["sample1", "sample2", "sample3", "sample4"],
    ["병원진단서A", "병원진단서B", "차량사고 이미지A", "차량사고 이미지B"],
  ];
  const realImages = ["default1", "default3", "default2", "default4"];

  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  //이미지 URL -> 파일 변환
  const convertUrlToFile = async (imageName: string) => {
    const imageUrl = `${process.env.PUBLIC_URL}/icon/${imageName}.png`;
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new File([blob], `${imageName}.png`, { type: "image/png" });
  };
  // 이미지 태그에서 File 객체 생성
  const handleSampleClick = async (index: number) => {
    const imageName = realImages[index];
    const file = await convertUrlToFile(imageName);
    setSelectedIndex(index);
    console.log(file);
    setSampleNum(index);
    //이후 upload handler 호출
    if (index >= 2) {
      handleCarisUpload(file);
    } else {
      handleDiagnosisUpload(file);
    }
  };
  return (
    <S.SampleContainer>
      <S.TextBox>
        <span>샘플이미지</span>
        <span>로 먼저 체험해 보세요!</span>
      </S.TextBox>
      <S.ContentBox>
        {samples[0].map((item, index) => (
          <S.Content
            key={index}
            onClick={() => {
              handleSampleClick(index);
            }}
          >
            {index === 0 && (
              <S.LottieWrapper>
                <Lottie animationData={sampleAnimation} />
              </S.LottieWrapper>
            )}
            <CheapfakeImg name={item} type="png" />
            <S.NameBox>
              {isLoading && selectedIndex === index ? (
                <Lottie
                  style={{ height: "18px" }}
                  animationData={loadingAnimationWhite}
                />
              ) : (
                <span>{samples[1][index]}</span>
              )}
            </S.NameBox>
          </S.Content>
        ))}
      </S.ContentBox>
    </S.SampleContainer>
  );
};

export default SampleBox;
