import React from "react";
import styled from "styled-components";
import { colors } from "../../../../../Utils/colors";
import CheapfakeImg from "../../../../../Common/CheapfakeImg/CheapfakeImg";
type ModalProps = {
  setIsClicked: (value: boolean) => void;
};

const S = {
  ModalContainer: styled.div`
    width: 500px;
    box-sizing: border-box;
    padding: 12px 12px 16px 20px;
    position: relative;
    border-radius: 12px;
    border: 1px solid ${colors.primary3};
    background: ${colors.white};
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.08);
    z-index: 2;
  `,
  ContentBox: styled.div`
    display: flex;
    padding: 4px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    & > img {
      width: 100%;
    }
  `,
  Text: styled.span`
    color: ${colors[700]};

    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
    letter-spacing: -0.16px;
  `,
  CloseBtn: styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
    width: 20px;
    height: 20px;
  `,
};
const ResultExplainModal = ({ setIsClicked }: ModalProps) => {
  return (
    <S.ModalContainer>
      <S.ContentBox>
        <S.Text>AI 기술 검토 결과</S.Text>
        <CheapfakeImg name="safetystage" type="png" />
      </S.ContentBox>
      <S.CloseBtn onClick={() => setIsClicked(false)}>
        <CheapfakeImg name="purple_x" type="png" />
      </S.CloseBtn>
    </S.ModalContainer>
  );
};

export default ResultExplainModal;
