import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { S } from "./modalstyle";
import useContact from "../../GlobalState/useContact";
import { useFormspark } from "@formspark/use-formspark";
import useIsMobile from "../../Hooks/useIsMobile";
import { useTranslation } from "react-i18next";
interface IForm {
  name: string;
  company: string;
  email: string;
  phone: string;
  text: string;
  checked: boolean;
}

const ContactModal = () => {
  const isMobile = useIsMobile();
  const { isContact, setIsContact } = useContact();
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    getValues,
    setFocus,
  } = useForm<IForm>({
    mode: "onSubmit",
    defaultValues: {
      name: "",
      company: "",
      email: "",
      phone: "",
      text: "",
      checked: false,
    },
  });
  const [isfilled, setIsFilled] = useState<boolean>(false);
  // formspark 연동
  const [submit, submitting] = useFormspark({
    formId: "SyfVTouWu",
  });
  // 매번 함수가 정의되지 않고 값이 변경될 때 정의되도록
  const onSubmitHandler = useCallback(
    async (data: IForm) => {
      console.log(data);
      if (isfilled) {
        try {
          setIsContact(false);
          await submit({ data });
        } catch (error) {
          console.log("Submit error", error);
        }
      }
    },
    [watch, isfilled],
  );

  //폼이 다 채워졌나 확인
  useEffect(() => {
    const subscription = watch((value) => {
      const { name, company, email, phone, text, checked } = value;
      const allFieldsFilled =
        name && company && email && phone && text && checked;
      setIsFilled(Boolean(allFieldsFilled));
      if (allFieldsFilled && !checked) {
        setValue("checked", true);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  const allFields = watch();
  const { t, i18n } = useTranslation();
  return (
    <S.Overlay onClick={() => setIsContact(false)}>
      <S.Container
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          e.stopPropagation()
        }
      >
        <S.CloseBtn
          src={process.env.PUBLIC_URL + "/icon/x.png"}
          onClick={() => setIsContact(false)}
        />
        <S.Txt>{t("contact")}</S.Txt>
        <S.Form onSubmit={handleSubmit(onSubmitHandler)}>
          <S.Item>
            <label htmlFor="name">{t("name")} *</label>
            <input
              {...register("name")}
              id="name"
              placeholder={t("namePlaceHolder")}
            />
          </S.Item>
          <S.Item>
            <label htmlFor="company">{t("company")} *</label>
            <input
              {...register("company")}
              id="company"
              placeholder={t("companyPlaceHolder")}
            />
          </S.Item>
          <S.Item>
            <label htmlFor="email">{t("email")} *</label>
            <input
              {...register("email")}
              type="email"
              id="email"
              placeholder={t("emailPlaceHolder")}
            />
          </S.Item>
          <S.Item>
            <label htmlFor="phone">{t("phone")} *</label>
            <input
              {...register("phone")}
              id="phone"
              placeholder={t("contactPlaceHolder")}
            />
          </S.Item>
          <S.Item>
            <label htmlFor="text">{t("details")} *</label>
            <S.TextArea
              {...register("text")}
              id="text"
              placeholder={t("detailsPlaceHolder")}
            />
          </S.Item>

          <S.RadioItem>
            <input
              type="checkbox"
              {...register("checked")}
              id="agree"
              checked={allFields.checked || false}
            />
            <label htmlFor="agree">{t("privacyPolicyAgree")}</label>
          </S.RadioItem>
          <S.Btn
            type="submit"
            isfilled={isfilled}
            id={
              isMobile
                ? "m_form_submit_header_inquiry"
                : "form_submit_header_inquiry"
            }
          >
            <p>{t("send")}</p>
          </S.Btn>
        </S.Form>
      </S.Container>
    </S.Overlay>
  );
};

export default ContactModal;
