import React, { useRef } from "react";
import styled from "styled-components";
import { colors } from "../../../../Utils/colors";
import CheapfakeImg from "../../../../Common/CheapfakeImg/CheapfakeImg";
import sampleAnimation from "../../../Detection/sampleAnimation.json";
import Lottie from "lottie-react";
import { S } from "../../Style/sampleboxstyle";

interface SampleProps {
  handleMileageisUploaded: (file: File) => void;
}

const SampleBox = ({ handleMileageisUploaded }: SampleProps) => {
  const samples = [
    ["1", "2", "3", "4"],
    ["계기판A", "계기판B", "계기판C", "계기판D"],
  ];
  //추후 마일리지 원본 이미지 name으로 수정!!!!!!
  const realImages = ["1", "3", "2", "4"];

  //이미지 URL -> 파일 변환
  const convertUrlToFile = async (imageName: string) => {
    const imageUrl = `${process.env.PUBLIC_URL}/icon/${imageName}.png`;
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new File([blob], `${imageName}.png`, { type: "image/png" });
  };
  // 이미지 태그에서 File 객체 생성
  const handleSampleClick = async (index: number) => {
    const imageName = realImages[index];
    const file = await convertUrlToFile(imageName);

    console.log(file);

    handleMileageisUploaded(file);
  };

  return (
    <S.SampleContainer>
      <S.TextBox>
        <span>샘플이미지</span>
        <span>로 먼저 체험해 보세요!</span>
      </S.TextBox>
      <S.ContentBox>
        {samples[0].map((item, index) => (
          <S.Content
            key={index}
            onClick={() => {
              handleSampleClick(index);
            }}
          >
            {index === 0 && (
              <S.LottieWrapper>
                <Lottie animationData={sampleAnimation} />
              </S.LottieWrapper>
            )}
            <CheapfakeImg name={item} type="png" />
            <S.NameBox>
              <span>{samples[1][index]}</span>
            </S.NameBox>
          </S.Content>
        ))}
      </S.ContentBox>
    </S.SampleContainer>
  );
};

export default SampleBox;
