export interface DefaultClaimType {
  code?: string;
  price?: number;
  startDate?: Date;
  endDate?: Date;
  part?: string[];
  type?: string[];
  content?: string;
}

export const defaultclaim: DefaultClaimType[] = [
  {
    code: "H12345",
    price: 4917474,
    startDate: new Date("2024-10-09"),
    endDate: new Date("2024-10-13"),
  },
  {
    code: "M77889",
    price: 2740126,
    startDate: new Date("2024-09-02"),
    endDate: new Date("2024-09-03"),
  },
  {
    part: ["front", "side"],
    type: ["scratch", "dent", "crack"],
    content: "본네트, 앞범퍼가 찌그러지고 파손되었으며 앞유리가 깨짐",
  },
  {
    part: ["back"],
    type: ["dent", "crack"],
    content: "트렁크, 뒷범퍼가 찌그러지고 파손됨",
  },
];
