export {};

import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../Utils/colors";
import UploadBox from "./DetectionBox/UploadBox";
import ClaimBox from "./DetectionBox/ClaimBox";
import ResultBox from "./DetectionBox/ResultBox";
import { set } from "date-fns";
import { useForm } from "react-hook-form";
import { S } from "../Styles/mainboxstyle";
import {
  CarForm,
  RequestType,
  CarSegRes,
  DataframeSplit,
  DiagnosisForm,
  ForgeryDetRes,
  ForgeryStatRes,
  ForgeryStatReq,
  OCRRes,
  CarClassifyRes,
} from "../../../Utils/type";
import CheapfakeImg from "../../../Common/CheapfakeImg/CheapfakeImg";
import axios from "axios";
import useBucketUpload from "../../../Hooks/useBucketUpload";
import { useDetectionState } from "../../../GlobalState/useDetectionState";
import { useLocation } from "react-router-dom";
import ReUploadBox from "./DetectionBox/ReUploadBox";
import SampleBox from "./DetectionBox/SampleBox";

import loadingAnimationWhite from "../../../../src/Common/loading_white.json";
import loadingAnimationBlue from "../../../../src/Common/loading_blue.json";
import Lottie from "lottie-react";

const MainBox = () => {
  const {
    currentStage,
    setCurrentStage,
    isCarUploaded,
    setIsCarUploaded,
    isDiagnosisUploaded,
    setIsDiagnosisUploaded,
    file,
    setFile,
    resetState,
  } = useDetectionState((state) => state);
  const formRef = useRef<HTMLFormElement | null>(null);
  //판별하기 버튼 클릭 boolean 상태값
  const [isDetected, setIsDetected] = useState<boolean>(false);
  //차량 분할 모델 결과
  const [segResult, setSegResult] = useState<CarSegRes>();
  //위조 탐지 결과
  const [forgeryResult, setForgeryResult] = useState<ForgeryStatRes>();
  //OCR 모델 결과
  const [ocrResult, setOcrResult] = useState<OCRRes>();
  //다시 업로드 해야될 때
  //파일 용량 10메가 넘을때, OCR 검출 안됐을 때, 차량 분할 모델 결과 검출 안됐을 때
  const [isAgainUpload, setIsAgainUpload] = useState<boolean>(false);
  const [uploadErrorReason, setUploadErrorReason] = useState<string | null>(
    null,
  );
  //클레임과 이미지 비교 후 최종 결과, 0:안전, 1:주의, 2:위험
  const [finalResult, setFinalResult] = useState<number | undefined>();
  const [fileName, setFileName] = useState<string>("");

  // 샘플을 눌렀을 때 sample 번호
  const [sampleNum, setSampleNum] = useState<number | null>(null);
  // 샘플 업로드 시 로딩 상태
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // 직접 업로드 시 로딩 상태
  const [isDiagnosisLoading, setIsDiagnosisLoading] = useState<boolean>(false);
  const [isCarLoading, setIsCarLoading] = useState<boolean>(false);
  // 최종 판별중 or not
  const [isDetecting, setIsDetecting] = useState<boolean>(false);

  //차량 분할 모델 연동
  const runSegmentation = useCallback(
    async (fileName: string) => {
      console.log("차량 분할 모델 호출 시작");
      if (!fileName) return;

      const reqBody: RequestType = {
        dataframe_split: {
          columns: ["bucket", "image_path"],
          data: [["ml-tracking-server", `${fileName}`]],
        } as DataframeSplit,
      };
      try {
        const response = await axios.post(
          "http://cheapfake.safeai.kr/yolo/predict",
          reqBody,
        );
        console.log(response);
        setSegResult(response.data);
        return response;
      } catch (error) {
        console.log("차량 분할 모델 에러: ", error);
        setIsAgainUpload(true);
        setUploadErrorReason("segmentation");
        return false;
      }
    },
    [isCarUploaded],
  );
  //진단서 OCR 모델 연동
  const runOCR = useCallback(
    async (fileName: string) => {
      console.log("OCR 모델 호출 시작");
      if (!fileName) return;

      const reqBody: RequestType = {
        dataframe_split: {
          columns: ["bucket", "image_path"],
          data: [["ml-tracking-server", `${fileName}`]],
        } as DataframeSplit,
      };
      try {
        const response = await axios.post(
          "http://cheapfake.safeai.kr/ocr/predict",
          reqBody,
        );
        console.log(response);
        console.log(response.data);
        setOcrResult(response.data);
        return response.data;
      } catch (error) {
        console.log("OCR 모델 에러: ", error);
        setIsAgainUpload(true);
        setUploadErrorReason("ocr");
        return false;
      }
    },
    [isDiagnosisUploaded],
  );
  //Forgery Detection model 연동
  const runForgDet = useCallback(
    async (fileName: string) => {
      console.log("위조 탐지 모델 호출 시작");
      if (!fileName) return;

      const reqBody: RequestType = {
        dataframe_split: {
          columns: ["bucket", "image_path"],
          data: [["ml-tracking-server", `${fileName}`]],
        } as DataframeSplit,
      };
      try {
        const response = await axios.post(
          "http://cheapfake.safeai.kr/forgery/predict",
          reqBody,
        );
        console.log(response);
        //job id 넘기기
        statusCheck(response.data as ForgeryDetRes);
      } catch (error) {
        console.log("위조 탐지 모델 에러: ", error);
        setIsAgainUpload(true);
      }
    },
    [isDiagnosisUploaded],
  );

  //job id 를 가지고 0.5초 마다 요청보내기
  const statusCheck = (data: ForgeryDetRes) => {
    console.log(data);
    const intervalDuration = 500; // interval 실행 주기
    const maxDuration = 10000; // 최대 10초 (10000ms)

    const interval = setInterval(async () => {
      try {
        const reqBody = {
          job_id: data.job_id,
        } as ForgeryStatReq;
        const response = await axios.post(
          "http://cheapfake.safeai.kr/forgery/predict/status",
          reqBody,
        );
        const statusResponse = response.data as ForgeryStatRes;

        // 위조 탐지 결과값이 0,1,2 중에 없으면 재 업로드
        if (![0, 1, 2].includes(statusResponse.result.model_output[0])) {
          setIsAgainUpload(true);
        }
        console.log(statusResponse);
        setForgeryResult(statusResponse);

        // statusResponse가 있을 경우 interval을 종료
        if (statusResponse) {
          setCurrentStage(2);
          clearInterval(interval);
        }
      } catch (error) {
        console.log(error);
      }
    }, intervalDuration);

    // 10초 후에 interval 종료
    setTimeout(() => {
      clearInterval(interval);
    }, maxDuration);
  };

  // 차량 사고 이미지 업로드 시
  const handleCarisUpload = useCallback(
    async (uploadedFile: File) => {
      // 파일 객체를 직접 받도록 수정
      const { uploadImgToBucket } = useBucketUpload();

      // 파일 용량 초과 시 재업로드
      if (uploadedFile.size > 10000000) {
        setIsAgainUpload(true);
        setUploadErrorReason("filesize");
        return;
      }
      setIsCarLoading(true);
      // 샘플 업로드 로딩
      setIsLoading(true);
      console.log(file);
      setFile(uploadedFile);

      setIsCarUploaded(true);

      // 차량 사고 이미지 버킷에 올리기
      const filename = await uploadImgToBucket(uploadedFile);

      if (filename) {
        setFileName(filename);
        const seg_res = await runSegmentation(filename); // 업로드된 파일 이름 전달, 모델 돌리기
        // 차량 분할 모델 결과가 적절히 나오면 forgery detection
        if (seg_res) {
          await runForgDet(filename);
        } else {
          setIsAgainUpload(true);
        }
      }
    },
    [isCarUploaded],
  );

  // 진단서 이미지 업로드 시
  const handleDiagnosisUpload = useCallback(
    async (uploadedFile: File) => {
      // 파일 객체를 직접 받도록 수정
      const { uploadImgToBucket } = useBucketUpload();

      // 파일 용량 초과 시 재업로드
      if (uploadedFile.size > 10000000) {
        setIsAgainUpload(true);
        setUploadErrorReason("filesize");
        return;
      }
      setIsDiagnosisLoading(true);
      // 샘플 업로드 로딩
      setIsLoading(true);
      console.log(uploadedFile);
      setFile(uploadedFile);

      setIsDiagnosisUploaded(true);

      // 진단서 이미지 버킷에 올리기
      const filename = await uploadImgToBucket(uploadedFile);
      if (filename) {
        setFileName(filename);
        const ocr_res = await runOCR(filename);
        // OCR 검출 모델 결과가 적절히 나오면 forgery detection
        if (ocr_res) {
          await runForgDet(filename);
        } else {
          setIsAgainUpload(true);
        }
      }
    },
    [isDiagnosisUploaded],
  );
  const [isFilled, setIsFilled] = useState<boolean>(false);

  const diagnosisFormMethods = useForm<DiagnosisForm>({
    mode: "onSubmit",
  });
  const carFormMethods = useForm<CarForm>({
    mode: "onSubmit",
  });

  //차량 이미지 위조 분류 모델 연동
  const runCarClassForg = useCallback(
    async (concatenatedClaim: string) => {
      console.log("차량 이미지 위조 분류 모델 호출 시작");
      console.log(concatenatedClaim);
      console.log(fileName);
      if (!fileName) return;

      const reqBody: RequestType = {
        dataframe_split: {
          columns: ["bucket", "image_path", "claim_text"],
          data: [["ml-tracking-server", `${fileName}`, `${concatenatedClaim}`]],
        } as DataframeSplit,
      };
      try {
        const response = await axios.post(
          "http://cheapfake.safeai.kr/classify/predict",
          reqBody,
        );
        console.log(response);
        console.log(forgeryResult);

        return Number(response.data.results);
      } catch (error) {
        console.log("차량 이미지 위조 분류 모델 에러: ", error);
        // setIsAgainUpload(true);
        // setUploadErrorReason("segmentation");
        return false;
      }
    },
    [fileName, forgeryResult],
  );
  //폼 데이터와 첨부된 파일 비교
  const onSubmitHandlerDiagnosis = useCallback(
    (data: DiagnosisForm) => {
      console.log(data);
      console.log(ocrResult);
      console.log(forgeryResult);
      if (!ocrResult) {
        console.error("OCR 결과가 없습니다.");
        return;
      }
      //ocrResult와 form 데이터 비교

      const dataStartDate = new Date(data.start).toISOString().split("T")[0];
      const ocrStartDate = ocrResult?.treatment_date?.start_time.split("T")[0];

      const dataEndDate = new Date(data.end).toISOString().split("T")[0];
      const ocrEndDate = ocrResult?.treatment_date?.end_time.split("T")[0];
      console.log(ocrResult);
      console.log(dataStartDate);
      console.log(ocrStartDate);
      console.log(dataEndDate);
      console.log(ocrEndDate);
      console.log(data.price);
      console.log(ocrResult.total_cost);
      console.log(data.code);
      console.log(ocrResult.treatment_code);
      const isEqual =
        data.price === ocrResult?.total_cost &&
        data.code === ocrResult?.treatment_code &&
        dataStartDate === ocrStartDate &&
        dataEndDate === ocrEndDate;

      console.log("IsEqual:", isEqual);
      //최종 결과
      if (forgeryResult) {
        if (isEqual && forgeryResult?.result.model_output[0] === 0) {
          setFinalResult(0);
        } else {
          if (isEqual && forgeryResult?.result.model_output[0] >= 1) {
            setFinalResult(1);
          } else {
            setFinalResult(2);
          }
        }
        setIsDetected(true);
        setCurrentStage(3);
      }
    },
    [ocrResult, forgeryResult],
  );

  const onSubmitHandlerCar = useCallback(
    async (data: CarForm) => {
      //클레임 데이터 텍스트들 concatenate해서 차량 이미지 위조 분류 모델 돌리기
      console.log(data);
      const concatenatedClaim = `${data.part.join(" ")} ${data.type.join(" ")} ${data.content}`;
      //최종 결과
      const result = await runCarClassForg(concatenatedClaim);

      console.log(forgeryResult?.result.model_output[0]);
      //최종 결과 resultbox 로 넘기기
      if (typeof result !== "undefined" && forgeryResult) {
        if (forgeryResult?.result.model_output[0] === 0 && result === 0) {
          setFinalResult(0);
        } else if (forgeryResult?.result.model_output[0] >= 1 && +result >= 1) {
          setFinalResult(2);
        } else {
          setFinalResult(1);
        }
        setIsDetected(true);
        setCurrentStage(3);
      }
    },
    [ocrResult, forgeryResult],
  );
  const handleDetectBtnClick = useCallback(async () => {
    if (formRef.current) {
      setIsDetecting(true);
      console.log("폼 제출 이벤트 호출");
      formRef.current.dispatchEvent(
        new Event("submit", { bubbles: true, cancelable: true }),
      );
    }
  }, [formRef]);

  //SampleBox로 넘기는 props
  const SampleProps = {
    handleDiagnosisUpload: handleDiagnosisUpload,
    handleCarisUpload: handleCarisUpload,
    setSampleNum: setSampleNum,
    isLoading: isLoading,
  };

  //UploadBox로 넘기는 props
  const UploadProps = {
    handleDiagnosisUpload: handleDiagnosisUpload,
    handleCarisUpload: handleCarisUpload,
    setSampleNum: setSampleNum,
    isCarLoading: isCarLoading,
    isDiagnosisLoading: isDiagnosisLoading,
  };

  //ClaimBox로 넘기는 props
  const ClaimProps = {
    setIsFilled: setIsFilled,
    diagnosisFormMethods: diagnosisFormMethods,
    carFormMethods: carFormMethods,
    onSubmitHandlerCar: onSubmitHandlerCar,
    onSubmitHandlerDiagnosis: onSubmitHandlerDiagnosis,
    segResult: segResult,
    forgeryResult: forgeryResult,
    sampleNum: sampleNum,
    formRef: formRef,
  };

  // ResultBox로 넘기는 props
  const ResultProps = {
    setIsDetected: setIsDetected,
    segResult: segResult,
    finalResult: finalResult,
    isCarUploaded: isCarUploaded,
    setIsCarLoading: setIsCarLoading,
    setIsDiagnosisLoading: setIsDiagnosisLoading,
  };
  useEffect(() => {
    //언마운트 시 전역 상태 초기화
    return () => {
      resetState();
    };
  }, []);
  useEffect(() => {
    if (currentStage === 1) setForgeryResult(undefined);
  }, [currentStage]);
  return (
    <S.MainContainer>
      <S.Head>
        <S.Title>보험청구서 위조 검토</S.Title>
      </S.Head>
      <S.ContentBox>
        <S.StageBox>
          <S.StageItem isNow={currentStage === 1}>
            <div>
              <div className="NumberBox">
                <p>1</p>
              </div>
              <div className="Text">이미지 첨부</div>
            </div>
          </S.StageItem>
          <S.StageItem isNow={currentStage === 2}>
            <div>
              <div className="NumberBox">
                <p>2</p>
              </div>
              <div className="Text">클레임 내용 입력</div>
            </div>
          </S.StageItem>
          <S.StageItem isNow={currentStage === 3}>
            <div>
              <div className="NumberBox">
                <p>3</p>
              </div>
              <div className="Text">결과 알림</div>
            </div>
          </S.StageItem>
        </S.StageBox>
        {!isDetected && (
          <S.UploadWrapper>
            {currentStage === 1 && !isAgainUpload && (
              <SampleBox {...SampleProps} />
            )}
            <S.ContentWrapper>
              {currentStage === 1 && !isAgainUpload && (
                <UploadBox {...UploadProps} />
              )}
              {currentStage === 2 &&
                !isAgainUpload &&
                (isDiagnosisUploaded || isCarUploaded) && (
                  <ClaimBox {...ClaimProps} />
                )}
              {isAgainUpload && (
                <ReUploadBox
                  reason={uploadErrorReason}
                  setIsAgainUpload={setIsAgainUpload}
                />
              )}
            </S.ContentWrapper>
          </S.UploadWrapper>
        )}
        {currentStage === 3 && isDetected && (
          <S.ResultWrapper>
            <ResultBox {...ResultProps} />
          </S.ResultWrapper>
        )}
      </S.ContentBox>
      <S.Bottom>
        {currentStage == 2 && (
          <S.BtnSet>
            <S.ReloadBtn
              onClick={() => {
                setIsDiagnosisUploaded(false);
                setIsCarUploaded(false);
                setCurrentStage(1);
                setIsLoading(false);
                setIsCarLoading(false);
                setIsDiagnosisLoading(false);
              }}
            >
              <CheapfakeImg name="arrow-left" type="png" />
              이미지 재첨부
            </S.ReloadBtn>
            <S.DetectBtn
              type="submit"
              isFilled={isFilled}
              onClick={handleDetectBtnClick}
            >
              {isDetecting ? (
                <Lottie
                  style={{ height: "16px" }}
                  animationData={loadingAnimationWhite}
                />
              ) : (
                <>
                  {isFilled ? (
                    <CheapfakeImg name="checkwhite" type="png" />
                  ) : (
                    <CheapfakeImg name="check500" type="png" />
                  )}
                  판별하기
                </>
              )}
            </S.DetectBtn>
          </S.BtnSet>
        )}
      </S.Bottom>
    </S.MainContainer>
  );
};

export default MainBox;
