import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { UseFormReturn } from "react-hook-form";

import {
  CarForm,
  CarSegRes,
  DiagnosisForm,
  ForgeryStatRes,
  MileageFormType,
  OCRRes,
} from "../../../../Utils/type";
import CheapfakeImg from "../../../../Common/CheapfakeImg/CheapfakeImg";

import { useDetectionState } from "../../../../GlobalState/useDetectionState";
import { Layer, Line, Stage } from "react-konva";
import MileageForm from "./ClaimForm/MileageForm";
import { S } from "../../Style/claimboxstyle";

interface ClaimProps {
  file: File | null;
  setIsFilled: (value: boolean) => void;
  onSubmitHandler: (value: MileageFormType) => void;
  mileageOutput: number;
  mileageFormMethods: UseFormReturn<MileageFormType>;
}

const ClaimBox = ({
  file,
  setIsFilled,
  onSubmitHandler,
  mileageFormMethods,
  mileageOutput,
}: ClaimProps) => {
  const fileUrl = useMemo(() => {
    if (file && file.size > 0) {
      return URL.createObjectURL(file);
    }
    return undefined;
  }, [file]);
  const [isClicked, setIsClicked] = useState<boolean>(false);

  return (
    <S.Container>
      <S.ImageContainer>
        <S.UpperBox>
          <p>SafeClaim OCR 인식 결과</p>
        </S.UpperBox>

        <S.ImageBox>
          <div className="Content">
            <img src={fileUrl} className="File" />
          </div>
        </S.ImageBox>
        <S.BottomBox>
          <CheapfakeImg name="checkgreen" type="png" />
          <span className="Text">주행거리 {mileageOutput}km</span>
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => {
              setIsClicked((prev) => !prev);
            }}
          >
            <span className="Plus">더보기</span>

            <CheapfakeImg name="rightchev" type="png" />
          </div>
          {/* 추후 모달 제작하여 넣기 */}
          {isClicked && (
            <S.ModalPosition>
              {/* <ResultExplainModal setIsClicked={setIsClicked} /> */}
            </S.ModalPosition>
          )}
        </S.BottomBox>
      </S.ImageContainer>
      <S.FormContainer>
        <MileageForm
          file={fileUrl}
          setIsFilled={setIsFilled}
          onSubmitHandler={onSubmitHandler}
          formMethods={mileageFormMethods}
        />
      </S.FormContainer>
    </S.Container>
  );
};

export default ClaimBox;
