import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import useLanguage from "../GlobalState/useLanguage";
import styled from "styled-components";
import { colors } from "../Utils/colors";
import CheapfakeImg from "../Common/CheapfakeImg/CheapfakeImg";
import { useTranslation } from "react-i18next";
import useBucketUpload from "../Hooks/useBucketUpload";
import { S } from "./Style/pagestyle";
import { DataframeSplit, RequestType } from "../Utils/type";
import axios from "axios";
import Lottie from "lottie-react";
import loadingAnimationWhite from "../../src/Common/loading_white.json";
import loadingAnimationBlue from "../../src/Common/loading_blue.json";
import useIsMobile from "../Hooks/useIsMobile";

const AIDetectPage = () => {
  const isMobile = useIsMobile(1280);
  const [currentTab, setCurrentTab] = useState<string>("person");
  const [modelResult, setModelResult] = useState<number>(0);
  // 업로드해서 모델 돌린 이미지 url
  const [uploadImgUrl, setUploadImgUrl] = useState<string>("");
  const [file, setFile] = useState<File>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [linkInput, setLinkInput] = useState<string>("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkInput(e.target.value);
  };
  const [currentIdx, setCurrentIdx] = useState<number>(0);
  const [attachMethod, setAttachMethod] = useState<string>("file");

  // url 로 파일 다운로드 후
  const downloadFileUrl = async () => {
    // 링크 url 확장자 png, jpg가 될 수 있도록
    const chars = linkInput.split(".");
    const extension = chars[chars.length - 1];
    if (linkInput === "" || !"jpgpng".includes(extension)) {
      console.log("확장자 에러");
      return;
    }

    const response = await fetch(linkInput);
    const data = await response.blob();
    const ext = linkInput.split(".").pop();
    const filename = linkInput.split("/").pop();
    const file = new File([data], filename!, { type: `image/${ext}` });
    if (file) {
      handleFileUpload(file);
    }
  };
  // 파일 업로드 시
  const handleFileUpload = useCallback(
    async (uploadedFile: File | undefined) => {
      console.log("파일 업로드 시작");

      if (!uploadedFile) return;
      setIsLoading(true);
      const { uploadImgToBucket } = useBucketUpload();

      // 파일 용량 초과 시 재업로드
      if (uploadedFile.size > 10000000) {
        setIsLoading(false);
        return;
      }
      setFile(uploadedFile);
      // 차량 사고 이미지 버킷에 올리기
      const filename = await uploadImgToBucket(uploadedFile);

      if (filename) {
        console.log("파일 업로드 완료");
        const res = await runDetect(filename); // 업로드된 파일 이름 전달, 모델 돌리기
        console.log(res);
        if (res) {
          setCurrentTab("");
          setModelResult(res.data.results.score);
          setUploadImgUrl(URL.createObjectURL(uploadedFile));
          setIsLoading(false);
        }
      }
    },
    [],
  );
  const runDetect = useCallback(
    async (fileName: string) => {
      console.log("AI 생성 이미지 탐지 시작");
      const reqBody: RequestType = {
        dataframe_split: {
          columns: ["bucket", "image_path"],
          data: [["ml-tracking-server", `${fileName}`]],
        } as DataframeSplit,
      };
      try {
        const response = await axios.post(
          "http://cheapfake.safeai.kr/deepfake/predict",
          reqBody,
        );
        return response;
      } catch (error) {
        console.log("AI 생성 이미지 탐지 에러: ", error);
        return false;
      }
    },
    [linkInput, file],
  );
  useEffect(() => {
    switch (currentTab) {
      case "person":
        setModelResult(100);
        return;
      case "artwork":
        setModelResult(65);
        return;
      case "nature":
        setModelResult(85);
        return;
    }
  }, [currentTab]);

  const exampleImgPreload = useCallback(() => {
    const paths = [
      process.env.REACT_APP_PUBLIC_URL + "/icon/example-img_2.png",
      process.env.REACT_APP_PUBLIC_URL + "/icon/example-img_3.png",
    ];

    paths.map((path, index) => {
      let img = new Image();
      img.src = path;
    });
  }, []);
  const { t, i18n } = useTranslation();
  // 렌더링 전에 예시 이미지 모두 preload
  useLayoutEffect(() => {
    exampleImgPreload();
  }, []);
  return (
    <S.Container>
      <S.UpperBox>
        <S.Title>
          {i18n.language === "ko" ? (
            <>
              <span className="Bold">{t("titleBold")}</span>
              <span className="Normal">{t("titleNormal")}</span>
            </>
          ) : (
            <>
              <span className="Normal">{t("titleNormal")}</span>
              <span className="Bold">{t("titleBold")}</span>
            </>
          )}
        </S.Title>
        <S.Tab>
          <S.TabBtn
            id={
              isMobile
                ? "m_click_sample_image_person"
                : "click_sample_image_person"
            }
            isCurrent={currentTab === "person"}
            onClick={() => {
              setCurrentIdx(0);
              setCurrentTab("person");
            }}
          >
            <span>{t("tab_person")}</span>
          </S.TabBtn>
          <S.TabBtn
            id={
              isMobile
                ? "m_click_sample_image_artwork"
                : "click_sample_image_artwork"
            }
            isCurrent={currentTab === "artwork"}
            onClick={() => {
              setCurrentIdx(1);
              setCurrentTab("artwork");
            }}
          >
            <span>{t("tab_artwork")}</span>
          </S.TabBtn>
          <S.TabBtn
            id={
              isMobile
                ? "m_click_sample_image_nature"
                : "click_sample_image_nature"
            }
            isCurrent={currentTab === "nature"}
            onClick={() => {
              setCurrentIdx(2);
              setCurrentTab("nature");
            }}
          >
            <span>{t("tab_nature")}</span>
          </S.TabBtn>
        </S.Tab>
      </S.UpperBox>
      <S.ContentBox>
        <S.LeftBox>
          <S.Image>
            {currentTab !== "" && (
              <CheapfakeImg
                name={"example-img_" + (currentIdx + 1)}
                type="png"
              />
            )}
            {currentTab === "" && <img src={uploadImgUrl} alt="" />}
          </S.Image>
        </S.LeftBox>
        <S.RightBox>
          <S.ResultContainer>
            <S.ResultHead>
              <div>
                <CheapfakeImg name="shield-check-blue" type="svg" />
                <span>{t("result_header")}</span>
              </div>
            </S.ResultHead>
            <S.ResultBody>
              <S.Result>
                <span>{t("result_text")}</span>
                <div>
                  {isLoading ? (
                    <Lottie
                      style={{ height: "54px" }}
                      animationData={loadingAnimationBlue}
                    />
                  ) : (
                    <>
                      <CheapfakeImg name="ai_robot" type="svg" />
                      <S.Percent>
                        <span>{modelResult}</span>
                        <span>%</span>
                      </S.Percent>
                    </>
                  )}
                </div>
              </S.Result>
            </S.ResultBody>
          </S.ResultContainer>
          <S.UploadContainer>
            <S.CheckText>
              <CheapfakeImg name="checkblue" type="svg" />
              <span>{t("check_text")}</span>
            </S.CheckText>
            <S.UploadBox>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <S.FileLinkBtn
                  id={
                    isMobile
                      ? "m_click_tab_upload_file"
                      : "click_tab_upload_file"
                  }
                  isMethodThis={attachMethod === "file"}
                  onClick={() => setAttachMethod("file")}
                >
                  {t("file")}
                </S.FileLinkBtn>
                <S.FileLinkBtn
                  id={
                    isMobile
                      ? "m_click_tab_upload_link"
                      : "click_tab_upload_link"
                  }
                  isMethodThis={attachMethod === "link"}
                  onClick={() => setAttachMethod("link")}
                >
                  {t("link")}
                </S.FileLinkBtn>
              </div>
              {attachMethod === "file" && (
                <S.UploadBtn
                  id={isMobile ? "m_click_upload_image" : "click_upload_image"}
                >
                  {isLoading ? (
                    <Lottie
                      style={{ height: "24px" }}
                      animationData={loadingAnimationWhite}
                    />
                  ) : (
                    <>
                      <CheapfakeImg name="upload" type="svg" />
                      <input
                        type="file"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          handleFileUpload(file);
                        }}
                        accept=".jpg,.png"
                      />

                      <span>{t("upload")}</span>
                    </>
                  )}
                </S.UploadBtn>
              )}
              {attachMethod === "link" && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    gap: "8px",
                    alignSelf: "stretch",
                  }}
                >
                  {" "}
                  <S.LinkInput
                    value={linkInput}
                    onChange={handleChange}
                    placeholder={t("linkUpload")}
                    type="url"
                  />
                  <S.RegisterBtn
                    isActive={linkInput !== ""}
                    onClick={downloadFileUrl}
                  >
                    {isLoading ? (
                      <Lottie
                        style={{ width: "50px", height: "19px" }}
                        animationData={loadingAnimationWhite}
                      />
                    ) : (
                      <span>{t("check")}</span>
                    )}
                  </S.RegisterBtn>
                </div>
              )}
            </S.UploadBox>
          </S.UploadContainer>
        </S.RightBox>
      </S.ContentBox>
    </S.Container>
  );
};

export default AIDetectPage;
