import styled from "styled-components";
import { colors } from "../../../Utils/colors";

export const S = {
  SampleContainer: styled.div`
    display: flex;
    padding: 0px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    @media ${(props) => props.theme.device.tablet} {
      gap: 36px;
      padding: 0;
    }
  `,
  TextBox: styled.div`
    & > span:nth-child(1) {
      color: ${colors.primary};
      font-family: Pretendard;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 22px */
      letter-spacing: -0.44px;
      @media ${(props) => props.theme.device.mobile} {
        color: ${colors[900]};
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 132%; /* 23.76px */
        letter-spacing: -0.54px;
      }
    }
    & > span:nth-child(2) {
      color: ${colors[900]};
      font-family: Pretendard;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -0.44px;
      @media ${(props) => props.theme.device.mobile} {
        color: ${colors[900]};
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 132%; /* 23.76px */
        letter-spacing: -0.54px;
      }
    }
  `,
  ContentBox: styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;
    width: 100%;
    @media ${(props) => props.theme.device.mobile} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      & > div:nth-child(3) {
        display: none;
      }
      & > div:nth-child(4) {
        display: none;
      }
    }
  `,
  Content: styled.div`
    display: grid;
    /* grid-template-rows: 3fr 0.7fr; */
    position: relative;
    cursor: pointer;
    & > img {
      border-radius: 12px 12px 0px 0px;
      border: 1px solid ${colors[900]};
      object-fit: contain;
      width: 100%;

      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
    }
  `,
  LottieWrapper: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    z-index: 1;
  `,
  NameBox: styled.div`
    display: flex;
    padding: 12px 0px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 0px 0px 12px 12px;
    background: ${colors[900]};
    width: 100%;
    border: 1px solid ${colors[900]};
    & > span {
      color: ${colors.white};
      text-align: center;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 18px */
      letter-spacing: -0.36px;
    }
  `,
};
