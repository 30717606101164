import styled from "styled-components";
import { colors } from "../../Utils/colors";

export const S = {
  BackgroundContainer: styled.div`
    background-color: ${colors[200]};
    @media ${(props) => props.theme.device.tablet} {
      max-width: 480px;
      margin: 0 auto;
    }
  `,
  FooterContainer: styled.div<{ isEnglish: boolean }>`
    display: flex;
    padding: 80px 48px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 1600px;
    margin: 0 auto;
    box-sizing: border-box;
    @media ${(props) => props.theme.device.tablet} {
      padding: ${(props) => (props.isEnglish ? "20px" : "80px 48px")};
    }
  `,
  LogoBox: styled.div`
    margin-bottom: 24px;
    & > * {
      width: 115px;
      height: 29px;
    }
  `,
  InfoBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    align-self: stretch;
  `,
  Info: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    @media ${(props) => props.theme.device.tablet} {
      gap: 0;
    }
    & > div:nth-child(1) {
      @media ${(props) => props.theme.device.tablet} {
        & > p:nth-child(1) {
          color: ${colors[700]};
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 22.4px */
          letter-spacing: -0.32px;
        }
      }
    }
    & > div {
      display: flex;
      align-items: center;
      gap: 6px;
      @media ${(props) => props.theme.device.tablet} {
        flex-direction: column;
        align-items: flex-start;
      }
      & > p {
        color: ${colors[700]};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        letter-spacing: -0.14px;
      }

      .Both {
        border-width: 0 1px 0;
        border-color: ${colors[500]};
        border-style: solid;
        padding: 0 8px;
        @media ${(props) => props.theme.device.tablet} {
          border: none;
          padding: 0;
        }
      }
      .Right {
        border-width: 0 1px 0 0;
        border-color: ${colors[500]};
        border-style: solid;
        padding: 0 8px 0 0;
        letter-spacing: -2%;
        @media ${(props) => props.theme.device.tablet} {
          border: none;
          padding: 0;
        }
      }
    }
  `,
  Footer: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    border-top: 1px solid ${colors[500]};
    padding-top: 28px;
    width: 100%;
    @media ${(props) => props.theme.device.tablet} {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
      & > div {
        display: flex;
        & > p {
          @media ${(props) => props.theme.device.tablet} {
            font-size: 14px;
            text-align: left;
            color: ${colors[700]};
            padding-right: 8px;
          }
        }
        & > p:nth-child(2) {
          @media ${(props) => props.theme.device.tablet} {
            font-size: 14px;
            text-align: left;
            color: ${colors[700]};
            border-width: 0 0 0 1px;
            border-color: ${colors[500]};
            border-style: solid;
            padding: 0 8px;
          }
        }
      }
    }
    & > p {
      color: ${colors[700]};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 16px */
      letter-spacing: -0.14px;
    }
    & > p:nth-child(2) {
      border-width: 0 1px 0;
      border-color: ${colors[500]};
      border-style: solid;
      padding: 0 8px;
      @media ${(props) => props.theme.device.tablet} {
        border: none;
        padding: 0;
      }
    }
    .Link {
      cursor: pointer;
    }
  `,
  TranslateBtn: styled.button`
    display: flex;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid ${colors[900]};
    background: ${colors.white};
    margin-top: 40px;
    cursor: pointer;
    &:hover {
      background: ${colors[100]};
    }
    & > span {
      color: ${colors[900]};
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 16px */
      letter-spacing: -0.16px;
    }
    & > img:nth-child(1) {
      width: 22px;
      height: 22px;
    }
    & > img:nth-child(1) {
      width: 24px;
      height: 24px;
    }
  `,
};
