import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { UseFormReturn } from "react-hook-form";
import { MileageFormType } from "../../../../../Utils/type";
import CheapfakeImg from "../../../../../Common/CheapfakeImg/CheapfakeImg";
import { S } from "./mileageformstyle";
import { NumericFormat } from "react-number-format";

interface Props {
  file: string | undefined;
  setIsFilled: (value: boolean) => void;
  onSubmitHandler: (data: MileageFormType) => void;
  formMethods: UseFormReturn<MileageFormType>;
}

const MileageForm = ({
  file,
  setIsFilled,
  onSubmitHandler,
  formMethods,
}: Props) => {
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const { register, watch, setValue, handleSubmit } = formMethods;

  // 폼의 모든 내용 채워졌나 확인
  useEffect(() => {
    const subscription = watch((value) => {
      const allFieldsFilled = value ? true : false;
      setIsFilled(Boolean(allFieldsFilled));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <S.ClaimBox>
      <S.Title>
        <span>주행거리 입력</span>
        <div
          onClick={() => setIsClicked((prev) => !prev)}
          style={{ cursor: "pointer" }}
        >
          <CheapfakeImg name="alert-circle" type="svg" />
        </div>
        {/* 추후 모달 제작하여 넣기 */}
        {isClicked && (
          <S.ModalPosition>
            {/* <TechExplainModal setIsClicked={setIsClicked} /> */}
          </S.ModalPosition>
        )}
      </S.Title>

      <S.Form onSubmit={handleSubmit(onSubmitHandler)}>
        <S.Item>
          <label className="FormLabel" htmlFor="price">
            현재 주행거리
            <span>*</span>
          </label>
          <NumericFormat
            {...register("mileage", { required: true, valueAsNumber: true })}
            id="price"
            placeholder="(예시)7896"
            className="FormInput"
            thousandSeparator={true}
            suffix="km"
            onValueChange={({ floatValue }) =>
              setValue("mileage", floatValue ?? 0)
            }
          />
        </S.Item>
      </S.Form>
    </S.ClaimBox>
  );
};

export default MileageForm;
