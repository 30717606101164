import {
  RequestType,
  DataframeSplit,
  MileageFormType,
} from "../../../Utils/type";
import CheapfakeImg from "../../../Common/CheapfakeImg/CheapfakeImg";
import axios from "axios";
import useBucketUpload from "../../../Hooks/useBucketUpload";
import { S } from "../Style/mainboxstyle";
import { useCallback, useEffect, useState } from "react";
import SampleBox from "./DetectionBox/SampleBox";
import { useForm } from "react-hook-form";
import UploadBox from "./DetectionBox/UploadBox";
import ClaimBox from "./DetectionBox/ClaimBox";
import ReUploadBox from "./DetectionBox/ReuploadBox";
import ResultBox from "./DetectionBox/ResultBox";
import useIsMobile from "../../../Hooks/useIsMobile";
import MobileHeader from "../../../Mobile/Common/MobileHeader";
import MobileFooter from "../../../Mobile/Common/MobileFooter";
import useMenu from "../../../GlobalState/useMenu";

const MainBox = () => {
  const [file, setFile] = useState<File | null>(null);
  const [mileageStage, setMileageStage] = useState<number>(1);
  const [isMileageUploaded, setIsMileageUploaded] = useState<boolean>(false);
  const [isAgainUpload, setIsAgainUpload] = useState<boolean>(false);
  const [isFilled, setIsFilled] = useState<boolean>(false);
  //판별하기 버튼 클릭 boolean 상태값
  const [isDetected, setIsDetected] = useState<boolean>(false);

  const [uploadErrorReason, setUploadErrorReason] = useState<string | null>(
    null,
  );
  const [mileageOutput, setMileageOutput] = useState<number>(0);
  const [userInput, setUserInput] = useState<number>(0);
  //최종 결과
  const [finalResult, setFinalResult] = useState<number | undefined>();

  //모델의 주행거리 인식 값 추출
  const handleMileageisUploaded = useCallback(
    async (uploadedFile: File) => {
      // 파일 객체를 직접 받도록 수정
      const { uploadImgToBucket } = useBucketUpload();
      setFile(uploadedFile);
      // 파일 용량 초과 시 재업로드
      if (uploadedFile.size > 10000000) {
        setUploadErrorReason("filesize");
        setIsAgainUpload(true);
        return;
      }
      console.log(uploadedFile);

      setIsMileageUploaded(true);

      // 진단서 이미지 버킷에 올리기
      const filename = await uploadImgToBucket(uploadedFile);
      if (filename) {
        const mileage_res = await runMileage(filename);
        if (mileage_res) {
          setMileageOutput(mileage_res.distance);
          if (isMobile) setMileageStage(3);
          else setMileageStage(2);
        } else {
          setIsAgainUpload(true);
        }
      }
    },
    [isMileageUploaded],
  );
  const runMileage = useCallback(
    async (fileName: string) => {
      console.log("마일리지 모델 호출 시작");
      if (!fileName) return;

      const reqBody: RequestType = {
        dataframe_split: {
          columns: ["bucket", "image_path"],
          data: [["ml-tracking-server", `${fileName}`]],
        } as DataframeSplit,
      };
      try {
        const response = await axios.post(
          "http://cheapfake.safeai.kr/mileage",
          reqBody,
        );
        console.log(response);

        return response.data;
      } catch (error) {
        console.log("마일리지 모델 에러: ", error);
        setIsAgainUpload(true);

        return false;
      }
    },
    [isMileageUploaded],
  );

  const mileageFormMethods = useForm<MileageFormType>({ mode: "onSubmit" });

  //모델 아웃풋과 사용자 주행거리 입력값 비교
  const onSubmitHandler = (data: MileageFormType) => {
    if (data) {
      console.log(data);
      console.log(mileageOutput);
      setUserInput(data.mileage);
      if (data.mileage === mileageOutput) setFinalResult(0);
      else setFinalResult(1);
    }
  };
  const handleDetectBtnClick = useCallback(async () => {
    if (isMileageUploaded && isFilled) {
      await mileageFormMethods.handleSubmit(onSubmitHandler)();
      // 폼 제출 후 초기화
      mileageFormMethods.reset();
    }

    setMileageStage(3);
    setIsDetected(true);
  }, [isMileageUploaded, isFilled]);
  const ClaimProps = {
    setIsFilled: setIsFilled,
    mileageFormMethods: mileageFormMethods,
    onSubmitHandler: onSubmitHandler,
    mileageOutput: mileageOutput,
    file: file,
  };

  // 모바일 반응
  const isMobile = useIsMobile();
  return (
    <>
      {isMobile && <MobileHeader />}
      <S.MainContainer>
        <S.Head>
          {isMobile ? (
            <>
              <S.Title>주행거리 자동 인식하기(OCR)</S.Title>
            </>
          ) : (
            <>
              <S.Title>자동차 주행거리 위조 검토</S.Title>
            </>
          )}
        </S.Head>
        <S.ContentBox>
          <S.StageBox>
            <S.StageItem isNow={mileageStage === 1}>
              <div>
                <div className="NumberBox">
                  <p>1</p>
                </div>
                <div className="Text">이미지 첨부</div>
              </div>
            </S.StageItem>
            <S.StageItem isNow={mileageStage === 2}>
              <div>
                <div className="NumberBox">
                  <p>2</p>
                </div>
                <div className="Text">주행거리 입력</div>
              </div>
            </S.StageItem>
            <S.StageItem isNow={mileageStage === 3}>
              <div>
                <div className="NumberBox">
                  <p>3</p>
                </div>
                <div className="Text">결과 알림</div>
              </div>
            </S.StageItem>
          </S.StageBox>

          <S.UploadWrapper>
            {mileageStage === 1 && !isAgainUpload && (
              <SampleBox handleMileageisUploaded={handleMileageisUploaded} />
            )}
            <S.ContentWrapper>
              {mileageStage === 1 && !isAgainUpload && (
                <UploadBox handleMileageisUploaded={handleMileageisUploaded} />
              )}
              {mileageStage === 2 && !isAgainUpload && isMileageUploaded && (
                <ClaimBox {...ClaimProps} />
              )}
              {isAgainUpload && (
                <ReUploadBox
                  reason={uploadErrorReason}
                  setIsAgainUpload={setIsAgainUpload}
                  setMileageStage={setMileageStage}
                  setIsMileageUploaded={setIsMileageUploaded}
                />
              )}
            </S.ContentWrapper>
          </S.UploadWrapper>

          {mileageStage === 3 && isDetected && (
            <S.ResultWrapper>
              <ResultBox
                setIsDetected={setIsDetected}
                finalResult={finalResult}
                setIsMileageUploaded={setIsMileageUploaded}
                setMileageStage={setMileageStage}
                file={file}
                userInput={userInput}
                mileageOutput={mileageOutput}
              />
            </S.ResultWrapper>
          )}
        </S.ContentBox>
        <S.Bottom>
          {mileageStage == 2 && (
            <S.BtnSet>
              <S.ReloadBtn
                onClick={() => {
                  setIsMileageUploaded(false);
                  setMileageStage(1);
                }}
              >
                <CheapfakeImg name="arrow-left" type="png" />
                이미지 재첨부
              </S.ReloadBtn>
              <S.DetectBtn
                type="submit"
                isFilled={isFilled}
                onClick={handleDetectBtnClick}
              >
                {isFilled ? (
                  <CheapfakeImg name="checkwhite" type="png" />
                ) : (
                  <CheapfakeImg name="check500" type="png" />
                )}
                판별하기
              </S.DetectBtn>
            </S.BtnSet>
          )}
        </S.Bottom>
      </S.MainContainer>
      {isMobile && <MobileFooter />}
    </>
  );
};

export default MainBox;
