import styled from "styled-components";
import { colors } from "../../Utils/colors";

export const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1600px;
    padding: 40px 48px 80px 48px;
    align-items: flex-start;
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;
    @media ${(props) => props.theme.device.tablet} {
      padding: 48px 20px;
      max-width: 480px;
      margin: 0 auto;
    }
  `,
  UpperBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  `,

  ContentBox: styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 44px;

    align-items: stretch;
    width: 100%;
    @media ${(props) => props.theme.device.tablet} {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  `,
  LeftBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
  `,
  Title: styled.div`
    .Normal {
      color: ${colors[900]};
      font-size: 5rem;
      font-style: normal;
      font-weight: 300;
      line-height: 130%;
      letter-spacing: -1.5px;
      @media ${(props) => props.theme.device.tablet} {
        font-size: 3.2rem;
      }
    }
    .Bold {
      color: ${colors[900]};
      font-size: 5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 65px */
      letter-spacing: -1.5px;
      @media ${(props) => props.theme.device.tablet} {
        font-size: 3.2rem;
      }
    }
  `,

  Tab: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
  `,
  TabBtn: styled.div<{ isCurrent: boolean }>`
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;
    border: ${(props) => (props.isCurrent ? null : `1px solid ${colors[900]}`)};
    background: ${(props) => (props.isCurrent ? colors[900] : null)};
    cursor: pointer;
    text-align: center;
    &:hover {
      background: ${(props) => (props.isCurrent ? colors[700] : colors[100])};
    }
    & > span {
      color: ${(props) => (props.isCurrent ? colors.white : colors[900])};
      text-align: center;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 25.2px */
      @media ${(props) => props.theme.device.tablet} {
        font-size: 1.8rem;
      }
    }
    @media ${(props) => props.theme.device.tablet} {
      border-radius: 8px;
    }
  `,
  Image: styled.div`
    width: 100%;
    border-radius: 20px;
    border: 1px solid ${colors[400]};

    overflow: hidden;

    @media ${(props) => props.theme.device.laptop} {
      aspect-ratio: 4/3;
      background: ${colors[900]};
    }
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  `,
  RightBox: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* 요소 간의 균등 간격 유지 */
    align-items: stretch;

    gap: 58px;
    height: 100%;
    @media ${(props) => props.theme.device.tablet} {
      gap: 44px;
    }
  `,
  ResultHead: styled.div`
    display: flex;

    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 16px 16px 0px 0px;
    background: #d4e1ff;
    @media ${(props) => props.theme.device.tablet} {
      padding: 16px 0;
    }
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      & > span {
        color: ${colors.primary5};
        font-family: Pretendard;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 20px */
        letter-spacing: -0.4px;
      }
    }
  `,
  ResultBody: styled.div`
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 16px 16px;
    background: #e9f1ff;
  `,
  Result: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    & > span {
      color: ${colors.primary5};
      text-align: center;
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 31.2px */
      letter-spacing: -0.48px;
      @media ${(props) => props.theme.device.tablet} {
        font-size: 2rem;
      }
    }
    & > div {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      & > img {
        width: 46px;
        height: 46px;
      }
    }
  `,
  Percent: styled.div`
    display: flex;
    justify-content: center;
    gap: 2px;
    white-space: nowrap;
    align-items: baseline;
    & > span:nth-child(1) {
      color: ${colors.primary5};
      font-size: 5.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -1.08px;
    }
    & > span:nth-child(2) {
      color: ${colors.primary5};
      font-size: 2.2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -0.44px;
    }
  `,
  ResultContainer: styled.div`
    display: grid;
    grid-template-rows: 1fr 4fr;
    align-self: stretch;
    width: 100%;
    height: 100%;
    flex: 1;
  `,
  UploadContainer: styled.div`
    display: grid;
    grid-template-rows: 1fr 5fr;
    align-self: stretch;
    width: 100%;
    height: 100%;
    flex: 1;
    gap: 20px;
    align-self: stretch;
    height: 100%;
  `,
  CheckText: styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    & > img {
      width: 24px;
      height: 24px;
    }
    & > span {
      color: ${colors[900]};
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 24px */
      letter-spacing: -0.48px;
    }
  `,
  UploadBox: styled.div`
    display: flex;
    padding: 32px 36px 44px 36px;
    flex-direction: column;
    align-items: flex-start;
    gap: 36px;
    align-self: stretch;
    border-radius: 16px;
    background: ${colors[100]};
  `,
  FileLinkBtn: styled.div<{ isMethodThis: boolean }>`
    display: flex;
    padding: 16px 0px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-bottom: ${(props) =>
      props.isMethodThis
        ? `4px solid ${colors[700]}`
        : `1px solid ${colors[500]}`};

    cursor: pointer;
    color: ${colors[700]};
    text-align: center;
    font-size: 1.9rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 19px */
    letter-spacing: -0.38px;
  `,
  UploadBtn: styled.label`
    display: flex;
    padding: 20px 4px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    opacity: 0.9;
    background: ${colors.primary};
    cursor: pointer;
    &:hover {
      opacity: 0.9;
      background: #2230ab;
    }
    & > img {
      width: 24px;
      height: 24px;
      /* 흰색으로 변경 */
      filter: invert(89%) sepia(89%) saturate(1%) hue-rotate(187deg)
        brightness(111%) contrast(97%);
    }
    & > span {
      color: ${colors.white};
      font-size: 1.9rem;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 19px */
      letter-spacing: -0.38px;
    }
    input {
      display: none;
    }
  `,
  LinkInput: styled.input`
    display: flex;
    padding: 20px 16px;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid ${colors[800]};
    width: 100%;
    background: ${colors.white};
    color: ${colors[800]};
    font-family: Pretendard;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 19px */
    letter-spacing: -0.38px;
  `,
  RegisterBtn: styled.div<{ isActive: boolean }>`
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-align: center;
    cursor: pointer;
    border-radius: 8px;
    white-space: nowrap;
    background: ${({ isActive }) => (isActive ? colors.primary : colors[600])};
    & > span {
      width: 50px;
      color: ${colors.white};
      font-size: 1.9rem;
      font-weight: 600;
      line-height: 100%; /* 19px */
      letter-spacing: -0.38px;
    }

    width: fit-content;
    height: fit-content;
  `,
  LottieWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 1.9rem;
    height: 1.9rem;
    padding: 20px;
    & > * {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  `,
};
